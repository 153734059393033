import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import Job from "./job.model";

interface JobState {
  jobs: Job[];
  setJobs: (jobs: Job[]) => void;
  addJob: (job: Job) => void;
  removeJob: (job: Job) => void;
  updateJob: (job: Job) => void;
  getJob: (id: string) => Job | undefined;
}

export const useJobStore = create<JobState>()(
  devtools(
    persist(
      (set, get) => ({
        jobs: [],
        setJobs: (_jobs) =>
          set(() => {
            return { jobs: _jobs.sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1)) };
          }),
        addJob: (job) =>
          set((state) => {
            return { jobs: state.jobs.some((a) => a.id === job.id) ? state.jobs : [...state.jobs, job] };
          }),
        removeJob: (job) =>
          set((state) => {
            return { jobs: state.jobs.filter((a) => a.id !== job.id) };
          }),
        updateJob: (job) =>
          set((state) => {
            return { jobs: state.jobs.map((a) => (a.id === job.id ? job : a)) };
          }),
        getJob: (id) => get().jobs.find((a) => a.id === id),
      }),
      {
        name: "jobs-storage",
      }
    )
  )
);
