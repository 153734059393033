import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import DiscreetButton from "../components/DiscreetButton";
import MainButton from "../components/MainButton";
import { useCustomerStore } from "../domains/customer/customer.store";
import { deleteJob } from "../domains/job/job.service";
import { useJobStore } from "../domains/job/job.store";
import { trackEvent } from "../services/amplitude.service";
import { XMarkIcon } from "@heroicons/react/20/solid";

function ViewVideo() {
  const { jobId } = useParams();
  const job = useJobStore((state) => (jobId ? state.getJob(jobId) : null));
  const { customer } = useCustomerStore();
  const navigate = useNavigate();

  const downloadVideo = async () => {
    if (!job) return;
    trackEvent("ViewVideo_Click_DownloadVideo", { jobId: job.id });
    const response = await fetch(job.video);
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "video.mp4");
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const deleteVideo = () => {
    if (!customer || !jobId) return;
    trackEvent("ViewVideo_Click_DeleteVideo", { jobId });
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      color: "#000",
      icon: "warning",
      buttonsStyling: false,
      iconColor: "#6A12F3",
      showCancelButton: true,
      confirmButtonColor: "#6A12F3",
      cancelButtonColor: "#000",
      confirmButtonText: "Yes, delete it!",
      customClass: {
        confirmButton: "bg-[#6A12F3] px-4 py-3 rounded-[15px] text-white font-semibold mr-2 text-sm",
        cancelButton: "bg-black px-4 py-3 text-sm rounded-[15px] text-white font-semibold",
        popup: "rounded-[20px]",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        trackEvent("ViewVideo_Click_DeleteVideoConfirm", { jobId });
        deleteJob(customer?.uid, jobId);
        navigate("/dashboard");
      } else trackEvent("ViewVideo_Click_DeleteVideoCancel", { jobId });
    });
  };

  const onClickClose = () => {
    if (!jobId) return;
    trackEvent("ViewVideo_Click_Close", { jobId });
    navigate("/dashboard?avatarId=" + job?.avatarId);
  }
  return (
    <div className={`h-inner max-h-inner w-screen`}>
      <div className={`px-3 md:px-7 pt-6 w-screen h-inner max-h-inner flex flex-col`}>
        <XMarkIcon className="h-8 w-8 mb-2 active:opacity-50" onClick={onClickClose} />
        <div className="flex-grow flex overflow-y-clip items-center h-full">
          {job ? (
            <video controls className="min-w-full min-h-full w-full h-full rounded-[20px] bg-black">
              <source src={job?.video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : undefined}
        </div>
        <div className="w-full flex items-center flex-col">
          <MainButton text="Download" className="w-full lg:w-[300px] mt-4" onClick={downloadVideo} />
          <DiscreetButton text="Delete video" className="my-3" onClick={deleteVideo} />
        </div>
      </div>
    </div>
  );
}

export default ViewVideo;
