import { useState } from "react";
import { ReactComponent as CheckSvg } from "../assets/icons/check.svg";
import { ReactComponent as CrossSvg } from "../assets/icons/cross.svg";
import example1 from "../assets/images/example1.png";
import example2 from "../assets/images/example2.png";
import example3 from "../assets/images/example3.png";
import ImageUploader from "../components/ImageUploader";
import { createCustomFaceModel } from "../domains/custom-face-model/custom-face-model.service";
import { useCustomerStore } from "../domains/customer/customer.store";
import { useNavigate } from "react-router-dom";

function AvatarCreationCustom() {
  const { customer } = useCustomerStore();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPreview, setIsPreview] = useState<boolean>(false);
  const [gender, setGender] = useState<"male" | "female">("male");
  const navigate = useNavigate();

  const uploadCustomAvatar = async (file: File) => {
    if (!customer) return;
    setIsLoading(true);
    const customFaceModel = await createCustomFaceModel(customer.uid, file, gender);
    navigate(`/avatar-creation-audio?customFaceId=${customFaceModel.id}`);
  };
  return (
    <div className={`h-inner w-screen max-h-inner`}>
      <div className="px-3 md:px-7 pt-6 flex flex-col  h-inner max-h-inner">
        <div className="text-xl font-bold mr-[50px]">Custom Avatar</div>
        <div className="mb-6">Upload a photo of whoever you want! </div>
        {!isPreview && (
          <div>
            <div className="text-base font-bold">For best results</div>
            <div className="text-sm">Portrait picture, 1 people, no glasses, no hat, show face clearly.</div>
            <div className="flex gap-3 mt-3">
              <div>
                <img src={example1} alt="bad_example_1" className="w-[200px] z-0" />
                <div className="rounded-full w-8 h-8 bg-red-500 flex items-center justify-center relative -top-4 z-20 left-[calc(50%_-_16px)]">
                  <CrossSvg className="w-4 h-4 fill-white" />
                </div>
              </div>
              <div>
                <img src={example2} alt="bad_example_2" className="w-[200px] z-0" />
                <div className="rounded-full w-8 h-8 bg-red-500 flex items-center justify-center relative -top-4 z-10 left-[calc(50%_-_16px)]">
                  <CrossSvg className="w-4 h-4 fill-white" />
                </div>
              </div>
              <div>
                <img src={example3} alt="good_example_1" className="w-[200px] z-0" />
                <div className="rounded-full w-8 h-8 bg-green-500 flex items-center justify-center relative -top-4 z-10 left-[calc(50%_-_16px)]">
                  <CheckSvg className="w-4 h-4 fill-white" />
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="mb-2">
          <div>What is the gender of the person on the picture?</div>

          <div className="flex flex-row items-center">
            <input
              className="mr-2 accent-black"
              type="radio"
              name="gender_voice"
              id="gender_voice_male"
              value="male"
              checked={gender === "male"}
              onChange={() => setGender("male")}
            />
            <label htmlFor="gender_voice_male" className={gender !== "male" ? "text-gray-400" : ""}>
              male
            </label>
            <input
              className="ml-6 mr-2 accent-black"
              type="radio"
              name="gender_voice"
              id="gender_voice_female"
              value="female"
              checked={gender === "female"}
              onChange={() => setGender("female")}
            />
            <label htmlFor="gender_voice_female" className={gender !== "female" ? "text-gray-400" : ""}>
              female
            </label>
          </div>
        </div>
        <div className="flex-1"></div>
        <ImageUploader onUpload={uploadCustomAvatar} onPreview={() => setIsPreview(true)} isLoading={isLoading} />
      </div>
    </div>
  );
}

export default AvatarCreationCustom;
