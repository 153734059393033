import { useCustomerStore } from "./customer.store";
import { doc, onSnapshot, Unsubscribe } from "firebase/firestore";
import { db } from "../../services/firebase.service";

let unsubCustomer: Unsubscribe | null = null;

export function setCustomerWithUid(uid: string) {
  const customerRef = doc(db, "customers", uid);
  unsubCustomer = onSnapshot(customerRef, (doc) => {
    const customerData = doc.data();
    useCustomerStore.getState().setCustomer({
      uid,
      email: customerData?.email,
      stripeId: customerData?.stripeId,
      credits: customerData?.credits ?? 0,
    });
  });
}

export function unsetCustomer() {
  unsubCustomer?.();
  useCustomerStore.getState().setCustomer(null);
}
