import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { Subscription } from "./subscription.model";

interface SubscriptionState {
    subscription: Subscription | null;
    setSubscription: (Subscription: Subscription | null) => void;
}

export const useSubscriptionStore = create<SubscriptionState>()(
    devtools(
        persist(
            (set) => ({
                subscription: null,
                setSubscription: (subscription) => set(() => { return { subscription } })
            }),
            {
                name: "subscription-storage",
            }
        )
    )
);
