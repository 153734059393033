import React from 'react'
import add from "../assets/icons/add.svg";

function ButtonAdd({ onClick = () => null, className = "" }: { onClick?: () => void, className?: string }) {
    return (
        <div onClick={onClick} className={`min-w-[100px] min-h-[100px]  w-[100px] h-[100px] rounded-[30px] flex justify-center items-center border-2 border-black active:border-[#4e4e4e] ` + className}>
            <div className="w-[85px] h-[85px] rounded-[20px] bg-black active:bg-[#4e4e4e] flex justify-center items-center">
                <img className="w-[35px] h-[35px]" src={add} alt="add_icon" />
            </div>
        </div>
    )
}

export default ButtonAdd