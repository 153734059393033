import { useAvatarStore } from "./avatar.store";
import { collection, onSnapshot, Unsubscribe, addDoc, Timestamp, deleteDoc, doc } from "firebase/firestore";
import { db } from "../../services/firebase.service";
import Avatar from "./avatar.model";
import FaceModel from "../face-model/face-model.model";
import AudioModel from "../audio-model/audio-model.model";

let unsubAvatar: Unsubscribe | null = null;

export function setAvatarsWithCustomerUid(uid: string) {
  const avatarsRef = collection(db, "customers", uid, "avatars");
  unsubAvatar = onSnapshot(avatarsRef, (snapshot) => {
    snapshot.docChanges().forEach((change) => {
      const avatar: Avatar = {
        id: change.doc.id,
        createdAt: change.doc.data().createdAt.toDate(),
        face: change.doc.data().face,
        audioModel: change.doc.data().audioModel,
        color: change.doc.data().color,
        genderVoice: change.doc.data().genderVoice,
      };
      if (change.type === "added") {
        useAvatarStore.getState().addAvatar(avatar);
      }
      if (change.type === "modified") {
        useAvatarStore.getState().updateAvatar(avatar);
      }
      if (change.type === "removed") {
        useAvatarStore.getState().removeAvatar(avatar);
      }
    });
  });
}

export function unsetAvatars() {
  unsubAvatar?.();
  useAvatarStore.getState().setAvatars([]);
}

export async function createAvatar(customerUid: string, faceModel: FaceModel, audioModel: AudioModel): Promise<Avatar> {
  const avatarsRef = collection(db, "customers", customerUid, "avatars");
  const firebaseAvatar = {
    createdAt: Timestamp.fromDate(new Date()),
    face: faceModel.faceUrl,
    color: faceModel.color,
    audioModel: audioModel.id,
    genderVoice: audioModel.genderVoice,
  };
  console.log("audioModel", audioModel);
  const doc = await addDoc(avatarsRef, firebaseAvatar);
  const avatar: Avatar = {
    id: doc.id,
    createdAt: firebaseAvatar.createdAt.toDate(),
    face: firebaseAvatar.face,
    color: firebaseAvatar.color,
    audioModel: firebaseAvatar.audioModel,
    genderVoice: firebaseAvatar.genderVoice,
  };
  return avatar;
}

export async function deleteAvatar(customerUid: string, avatarId: string) {
  const avatarRef = doc(db, "customers", customerUid, "avatars", avatarId);
  await deleteDoc(avatarRef);
}
