import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import CustomFaceModel from "./custom-face-model.model";

interface CustomFaceModelState {
    customFaceModels: CustomFaceModel[]
    setCustomFaceModels: (customFaceModels: CustomFaceModel[]) => void;
    addCustomFaceModel: (customFaceModel: CustomFaceModel) => void;
    removeCustomFaceModel: (customFaceModel: CustomFaceModel) => void;
    updateCustomFaceModel: (customFaceModel: CustomFaceModel) => void;
    getCustomFaceModel: (id: string) => CustomFaceModel | undefined;
}

export const useCustomFaceModelStore = create<CustomFaceModelState>()(
    devtools(
        persist(
            (set, get) => ({
                customFaceModels: [],
                setCustomFaceModels: (customFaceModels) => set(() => { return { customFaceModels } }),
                addCustomFaceModel: (customFaceModel) => set((state) => { return { customFaceModels: state.customFaceModels.some(a => a.id === customFaceModel.id) ? state.customFaceModels : [...state.customFaceModels, customFaceModel] } }),
                removeCustomFaceModel: (customFaceModel) => set((state) => { return { customFaceModels: state.customFaceModels.filter((a) => a.id !== customFaceModel.id) } }),
                updateCustomFaceModel: (customFaceModel) => set((state) => { return { customFaceModels: state.customFaceModels.map((a) => a.id === customFaceModel.id ? customFaceModel : a) } }),
                getCustomFaceModel: (id) => get().customFaceModels.find((a) => a.id === id)
            }),
            {
                name: "customFaceModels-storage",
            }
        )
    )
);
