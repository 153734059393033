import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./routes/Home";
import SignIn from "./routes/SignIn";
import Subscribe from "./routes/Subscribe";
import Menu from "./components/Menu";
import Dashboard from "./routes/Dashboard";
import AvatarCreationFace from "./routes/AvatarCreationFace";
import AvatarCreationAudio from "./routes/AvatarCreationAudio";
import PageNotFound from "./routes/PageNotFound";
import CreateVideo from "./routes/CreateVideo";
import ViewVideo from "./routes/ViewVideo";
import Settings from "./routes/Settings";
import AvatarCreationCustom from "./routes/AvatarCreationCustom";
import Terms from "./routes/Terms";
import Privacy from "./routes/Privacy";
import { initAmplitude } from "./services/amplitude.service";
import { initSentry } from "./services/sentry.service";
initSentry();
initAmplitude();

// Define a CSS variable --innerh with the height of the inner window
document.documentElement.style.setProperty("--innerh", `${window.innerHeight}px`);
window.addEventListener("resize", () => {
  document.documentElement.style.setProperty("--innerh", `${window.innerHeight}px`);
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <PageNotFound />,
  },
  {
    path: "/signin",
    element: <SignIn />,
  },
  {
    path: "/subscribe",
    element: <Subscribe />,
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    path: "/settings",
    element: <Settings />,
  },
  {
    path: "/avatar-creation-face",
    element: <AvatarCreationFace />,
  },
  {
    path: "/avatar-creation-custom",
    element: <AvatarCreationCustom />,
  },
  {
    path: "/avatar-creation-audio",
    element: <AvatarCreationAudio />,
  },
  {
    path: "/create-video",
    element: <CreateVideo />,
  },
  {
    path: "/view-video/:jobId",
    element: <ViewVideo />,
  },
  {
    path: "/terms",
    element: <Terms />,
  },
  {
    path: "/privacy",
    element: <Privacy />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <Menu />
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
