import { RadioGroup } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";
import { ReactComponent as ProcessingSvg } from "../assets/icons/processing.svg";
import { trackEvent } from "../services/amplitude.service";
import { createCheckoutSession } from "../services/stripe.service";
import { useCustomerStore } from "../domains/customer/customer.store";
import { useNavigate } from "react-router-dom";

const frequencies = [
  { value: "monthly", label: "Monthly", priceSuffix: "/month" },
  { value: "annually", label: "Annually  (-20%)", priceSuffix: "/year" },
];
const priceIds: Record<string, string>[] =
  process.env.REACT_APP_ENV === "production"
    ? [
        { monthly: "price_1NhJMaFFvd27AEom7HORs78r", annually: "price_1NhJMaFFvd27AEombe6HmJoQ" },
        { monthly: "price_1NhJOGFFvd27AEomMZZUuDZZ", annually: "price_1NhJOGFFvd27AEomLHTBBsZE" },
        { monthly: "price_1NhJOKFFvd27AEomMIXDMRwh", annually: "price_1NhJOKFFvd27AEomzdb6REcL" },
      ]
    : [
        { monthly: "price_1OEW6lFFvd27AEomwrdRrJku", annually: "price_1Nfj7TFFvd27AEombSJHMLL2" },
        { monthly: "price_1NfjA1FFvd27AEomXgVpANdX", annually: "price_1NfjA1FFvd27AEomtmmOJmUP" },
        { monthly: "price_1NfjCdFFvd27AEomIg0zA4Ch", annually: "price_1NfjCeFFvd27AEomv8f4qMaZ" },
      ];

const freeTrial = process.env.REACT_APP_ENV === "production" ? "price_1OEf9LFFvd27AEom0XHaujId" : "price_1OEewPFFvd27AEomULZysCx6";
const tiers = [
  {
    idx: 0,
    name: "Starter",
    id: "starter-plan",
    href: "#",
    // originalPrice: { monthly: "$12", annually: "$115" } as Record<string, string>,
    price: { monthly: "$10", annually: "$96" } as Record<string, string>,
    description: "Ideal for occasional users or first-time explorers.",
    features: ["Includes Commercial License", "Generate up to 8 minutes of video per month", "Create and manage up to 3 unique custom avatars"],
    mostPopular: false,
    priceId: priceIds[0],
  },
  {
    idx: 1,
    name: "Plus",
    id: "plus-plan",
    href: "#",
    // originalPrice: { monthly: "$39", annually: "$384" } as Record<string, string>,
    price: { monthly: "$30", annually: "$288" } as Record<string, string>,
    description: "Perfect for regular users, transform more videos with ease.",
    features: [
      "<b>20 Free Credits</b> during Free Trial",
      "Includes Commercial License",
      "Generate up to 30 minutes of video per month",
      "Create and manage up to 10 unique custom avatars",
    ],
    mostPopular: true,
    priceId: priceIds[1],
  },
  {
    idx: 2,
    name: "Pro",
    id: "pro-plan",
    href: "#",
    // originalPrice: { monthly: "$132", annually: "$1268" } as Record<string, string>,
    price: { monthly: "$120", annually: "$1152" } as Record<string, string>,
    description: "Built for professionals or heavy users, unleash your creativity with extended video time.",
    features: [
      "Includes Commercial License",
      "Generate up to 120 minutes of video per month",
      "Create and manage unlimited unique custom avatars",
      "Early access to new features and improvements",
      // "Access to 'Buy more credits' option for increased flexibility",
    ],
    mostPopular: false,
    priceId: priceIds[2],
  },
];

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}

export default function Subscribe({ isLanding = false }: { isLanding?: boolean }) {
  const [frequency, setFrequency] = useState(frequencies[0]);
  const [isPlanLoading, setIsPlanLoading] = useState<boolean[]>([false, false, false]);
  const { customer } = useCustomerStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (isLanding) return;
    console.log(customer);
    if (!customer) {
      navigate("/signin");
      return;
    }
    if (customer.credits > 0) navigate("/dashboard");
  }, [customer, navigate, isLanding]);

  const launchCheckoutSection = async (tier: any, isFreeTrial = false) => {
    if (isLanding) return navigate("/signin");
    if (isPlanLoading.some((isLoading) => isLoading)) return;
    trackEvent("Subscribe_Click_BuyPlan", {
      tier: tier.name,
      price: tier.price[frequency.value],
      price_id: tier.priceId[frequency.value],
      frequency: frequency.value,
    });
    const isPlanLoadingState = [...isPlanLoading];
    isPlanLoadingState[tier.idx] = true;
    setIsPlanLoading(isPlanLoadingState);
    await createCheckoutSession(isFreeTrial ? freeTrial : tier.priceId[frequency.value]);
  };

  return (
    <div className="bg-white pt-6 pb-6">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-4xl text-center">
          <h2 className="text-base font-semibold leading-7 text-indigo-600">Pricing</h2>
          <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">Choose the plan that suits your needs!</p>
        </div>
        <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
          From occasional to professional use, find the perfect plan for your video transformation needs.
        </p>
        <div className="mt-8 flex justify-center">
          <RadioGroup
            value={frequency}
            onChange={setFrequency}
            className="grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200"
          >
            <RadioGroup.Label className="sr-only">Payment frequency</RadioGroup.Label>
            {frequencies.map((option) => (
              <RadioGroup.Option
                key={option.value}
                value={option}
                className={(frequency.value === option.value ? "bg-indigo-600 text-white" : "text-gray-500") + " cursor-pointer rounded-full px-2.5 py-1"}
              >
                <span>{option.label}</span>
              </RadioGroup.Option>
            ))}
          </RadioGroup>
        </div>
        <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {tiers.map((tier) => (
            <div key={tier.id} className={classNames(tier.mostPopular ? "ring-2 ring-indigo-600" : "ring-1 ring-gray-200", "rounded-3xl p-8 xl:p-10")}>
              <div className="flex items-center justify-between gap-x-4">
                <h3 id={tier.id} className={classNames(tier.mostPopular ? "text-indigo-600" : "text-gray-900", "text-lg font-semibold leading-8")}>
                  {tier.name}
                </h3>
                {tier.mostPopular ? <p className="rounded-full bg-indigo-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-indigo-600">Most popular</p> : null}
              </div>
              <p className="mt-4 text-sm leading-6 text-gray-600">{tier.description}</p>
              <p className="mt-6 flex items-baseline gap-x-1">
                {/* <span className="text-4xl font-bold tracking-tight text-gray-300 line-through">{tier.originalPrice[frequency.value]}</span> */}
                {tier.mostPopular && frequency?.value === "monthly" ? (
                  <div>
                    <span className="text-4xl font-bold tracking-tight text-gray-900">Free </span>
                    <span className="text-sm font-semibold leading-6 text-gray-600">
                      then {tier.price[frequency.value]}
                      {frequency.priceSuffix}
                    </span>
                  </div>
                ) : (
                  <div>
                    <span className="text-4xl font-bold tracking-tight text-gray-900 ">{tier.price[frequency.value]}</span>
                    <span className="text-sm font-semibold leading-6 text-gray-600">{frequency.priceSuffix} </span>
                  </div>
                )}
              </p>
              {/* <p className="text-sm text-black font-bold">(25% beta reduction)</p> */}

              {tier.mostPopular && frequency?.value === "monthly" ? (
                <div>
                  <button
                    onClick={() => launchCheckoutSection(tier, true)}
                    aria-describedby={tier.id}
                    className="bg-indigo-600 text-white shadow-sm hover:bg-indigo-500 w-full mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    <div className="h-6 flex items-center justify-center">
                      {isPlanLoading[tier.idx] ? <ProcessingSvg className={`w-5 h-5 spin ${tier.mostPopular ? "fill-white" : "fill-indigo-600"}`} /> : "Free Trial"}
                    </div>
                  </button>
                  <div className="text-center text-xs mt-1">or</div>
                  <div onClick={() => launchCheckoutSection(tier)} className="text-center text-sm mt-1 text-indigo-600 hover:text-indigo-400 cursor-pointer">
                    Buy Plan
                  </div>
                </div>
              ) : (
                <button
                  onClick={() => launchCheckoutSection(tier)}
                  aria-describedby={tier.id}
                  className="text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300 w-full mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  <div className="h-6 flex items-center justify-center">{isPlanLoading[tier.idx] ? <ProcessingSvg className="w-5 h-5 spin fill-indigo-600" /> : "Buy plan"}</div>
                </button>
              )}
              <ul className="mt-8 space-y-3 text-sm leading-6 text-gray-600 xl:mt-10">
                {tier.features.map((feature) => (
                  <li key={feature} className="flex gap-x-3">
                    <CheckIcon className="h-6 w-5 flex-none text-indigo-600" aria-hidden="true" />
                    <div dangerouslySetInnerHTML={{ __html: feature }}></div>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
