import React, { useState } from "react";
import play from "../assets/icons/play.svg";
import AudioModelModel from "../domains/audio-model/audio-model.model";
import { ReactComponent as ProcessingSvg } from "../assets/icons/processing.svg";

interface AudioModelProps {
  className?: string;
  audioModel: AudioModelModel;
  isSelected?: boolean;
  onClick?: () => void;
}

function AudioModel({ className = "", audioModel, isSelected = false, onClick = () => null }: AudioModelProps) {
  const [duration, setDuration] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const onClickPlay = async () => {
    console.log(audioModel.audioUrl);
    onClick();
    setDuration(0);
    setIsLoading(true);
    const audio = new Audio();
    // Ajout d'un écouteur d'erreur
    audio.addEventListener("error", function () {
      console.error("Erreur de chargement audio:", audio.error);
    });
    audio.currentTime = 0;
    audio.src = audioModel.audioUrl;
    const waitForPlay = (): void => {
      if (audio.readyState === 0) {
        setTimeout(waitForPlay, 100);
        return;
      }
      setIsLoading(false);
      setDuration(audio.duration);
      audio.play().catch((error) => console.error("Erreur de lecture audio:", error));
    };
    waitForPlay();
  };

  return (
    <div className={"active:opacity-50 " + className} onClick={onClickPlay}>
      <div className="flex flex-col items-center">
        <div
          className={"w-full aspect-square rounded-[30px] overflow-hidden bg-[#C3CED0] flex justify-center items-center relative"}
          style={isSelected ? { borderColor: "#6A12F3", borderWidth: 4 } : {}}
        >
          <div className="absolute top-0 left-0 w-[0%] h-full bg-white z-0 opacity-50" style={{ animation: duration ? `audio-play ${duration}s linear` : "none" }} />
          {isLoading ? <ProcessingSvg className="w-8 h-8 z-0 spin fill-black opacity-50" /> : <img className="w-[60px] h-[60px] z-10" src={play} alt="play_icon" />}
        </div>
        <div className={`text-xs font-bold mt-1 ${isSelected ? "text-white bg-[#6A12F3] py-1 px-4 rounded-full" : ""}`}>{audioModel.id}</div>
      </div>
    </div>
  );
}

export default AudioModel;
