import React from 'react'

interface DiscreetButtonProps {
    text: string,
    className?: string,
    onClick?: () => void
}

function DiscreetButton({ text, className = "", onClick = undefined }: DiscreetButtonProps) {
    return (
        <div onClick={onClick} className={"text-xs text-gray-500 " + className}>{text}</div>
    )
}

export default DiscreetButton