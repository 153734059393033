import { Unsubscribe, setDoc, collection, deleteDoc, doc, onSnapshot, Timestamp, getDoc } from "firebase/firestore";
import { db, uploadFile } from "../../services/firebase.service";
import CustomFaceModel from "./custom-face-model.model";
import { useCustomFaceModelStore } from "./custom-face-model.store";

let unsubCustomFaceModel: Unsubscribe | null = null;

export function setCustomFaceModelsWithCustomerUid(uid: string) {
  useCustomFaceModelStore.getState().setCustomFaceModels([]);
  const customFaceModelsRef = collection(db, "customers", uid, "custom_face_models");
  unsubCustomFaceModel = onSnapshot(customFaceModelsRef, (snapshot) => {
    snapshot.docChanges().forEach((change) => {
      const customFaceModel: CustomFaceModel = {
        id: change.doc.id,
        createdAt: change.doc.data().createdAt.toDate(),
        status: change.doc.data().status,
        referenceUrl: change.doc.data().referenceUrl,
        faceUrl: change.doc.data().faceUrl,
        color: change.doc.data().color,
        gender: change.doc.data().gender,
      };
      if (change.type === "added") {
        useCustomFaceModelStore.getState().addCustomFaceModel(customFaceModel);
      }
      if (change.type === "modified") {
        useCustomFaceModelStore.getState().updateCustomFaceModel(customFaceModel);
      }
      if (change.type === "removed") {
        useCustomFaceModelStore.getState().removeCustomFaceModel(customFaceModel);
      }
    });
  });
}

export function unsetCustomFaceModels() {
  unsubCustomFaceModel?.();
  useCustomFaceModelStore.getState().setCustomFaceModels([]);
}

export async function deleteCustomFaceModel(customerUid: string, customFaceModelId: string) {
  const customFaceModelRef = doc(db, "customers", customerUid, "custom_face_models", customFaceModelId);
  await deleteDoc(customFaceModelRef);
}

export async function createCustomFaceModel(customerUid: string, referenceFile: File, gender: "male" | "female") {
  const collectionRef = collection(db, "customers", customerUid, "custom_face_models");
  const customFaceModelRef = doc(collectionRef);
  const extension = referenceFile.name.split(".").pop();
  const bucketPath = `customers/${customerUid}/custom_face_models/${customFaceModelRef.id}.${extension}`;
  const referenceUrl = await uploadFile(referenceFile, bucketPath);
  const customFaceModel: CustomFaceModel = {
    id: customFaceModelRef.id,
    createdAt: new Date(),
    status: "pending",
    referenceUrl: referenceUrl,
    faceUrl: "",
    color: ["#F1DC99", "#C3CED0", "#D2BBC9", "#BBD2BB"][Math.floor(Math.random() * 4)],
    gender: gender,
  };
  await setDoc(customFaceModelRef, {
    createdAt: Timestamp.fromDate(customFaceModel.createdAt),
    status: customFaceModel.status,
    referenceUrl: customFaceModel.referenceUrl,
    faceUrl: customFaceModel.faceUrl,
    color: customFaceModel.color,
    gender: customFaceModel.gender,
  });
  return customFaceModel;
}

export async function getCustomFaceModel(customerUid: string, customFaceModelId: string): Promise<CustomFaceModel | null> {
  const docRef = doc(db, "customers", customerUid, "custom_face_models", customFaceModelId);
  const docSnap = await getDoc(docRef);
  if (!docSnap.exists()) return null;
  const data = docSnap.data();
  const customFaceModel: CustomFaceModel = {
    id: docSnap.id,
    createdAt: data.createdAt.toDate(),
    status: data.status,
    referenceUrl: data.referenceUrl,
    faceUrl: data.faceUrl,
    color: data.color,
    gender: data.gender,
  };
  return customFaceModel;
}
