import React, { useRef, useState } from "react";
import MainButton from "./MainButton";
// import Swal from "sweetalert2";

interface ImageUploaderProps {
  onUpload: (file: File) => void;
  onPreview?: (file: File) => void;
  isLoading?: boolean;
}

function ImageUploader({ onUpload, isLoading = false, onPreview = () => null }: ImageUploaderProps) {
  const [preview, setPreview] = useState(null);
  const [file, setFile] = useState<File | null>(null);
  const fileInputRef = useRef<any>();

  const handleImageChange = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      setFile(file);
      const reader: any = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
        onPreview(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const triggerFileSelectPopup = () => fileInputRef?.current.click();

  const uploadImage = () => {
    if (isLoading) return;
    if (!file) return;
    onUpload(file);
  };

  return (
    <div className="flex-shrink flex flex-col items-center w-full max-h-full">
      {preview && (
        <div>
          <img className="rounded-[20px] h-[100vw]" src={preview} alt="preview" />
        </div>
      )}
      <div className="w-full flex justify-center flex-shrink">
        <input type="file" accept="image/*" onChange={handleImageChange} ref={fileInputRef} className="hidden" />
        <MainButton
          text={preview ? "Select this photo" : "Upload photo"}
          subtext={undefined}
          className="w-full lg:w-[300px] my-4"
          isDisabled={false}
          isLoading={isLoading}
          onClick={preview ? uploadImage : triggerFileSelectPopup}
        />
      </div>
    </div>
  );
}

export default ImageUploader;
