import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import AudioModel from "./audio-model.model";

interface AudioModelState {
    audioModels: AudioModel[]
    setAudioModels: (audioModels: AudioModel[]) => void;
}

export const useAudioModelStore = create<AudioModelState>()(
    devtools(
        persist(
            (set) => ({
                audioModels: [],
                setAudioModels: (audioModels) => set(() => { return { audioModels } })
            }),
            {
                name: "audio-models-storage",
            }
        )
    )
);
