import React, { forwardRef, useState } from 'react'
import Avatar from '../domains/avatar/avatar.model'
import selected from "../assets/icons/selected.svg";
import { TrashIcon } from '@heroicons/react/20/solid'
import { ReactComponent as ProcessingSvg } from "../assets/icons/processing.svg";

interface AvatarMinProps {
    avatar: Avatar,
    isSelected?: boolean,
    onClick?: () => void,
    className?: string
    onDeleteClick?: () => void
}

function AvatarMin({ avatar, isSelected = false, onClick = () => null, className = "", onDeleteClick = () => null }: AvatarMinProps, ref: any) {
    const [isImageLoading, setImageLoading] = useState(true);

    return (
        <div className={className} onClick={onClick} ref={ref}>
            <div className={'flex flex-col items-center w-[100px]'}>
                <div className={`w-[100px] h-[100px] rounded-[30px] flex justify-center items-center border-2 relative`} style={{ borderColor: isSelected ? "#6A12F3" : avatar.color }}>
                    {(isImageLoading) ?
                        <div className="w-[85px] h-[85px] rounded-[20px] flex items-center justify-center" style={{ backgroundColor: avatar.color }}>
                            <ProcessingSvg className="w-8 h-8 z-0 spin fill-white opacity-80" />
                        </div>
                        : undefined}
                    <img
                        src={avatar.face}
                        alt="avatar_picture"
                        onLoad={() => setImageLoading(false)}
                        className={`w-[85px] h-[85px] rounded-[20px] ${isImageLoading ? 'hidden' : 'block'}`}
                    />
                    {(isSelected) ? <TrashIcon onClick={onDeleteClick} className="absolute right-3 bottom-3 w-6 h-6 text-[#6A12F3] opacity-30 hover:opacity-100 active:opacity-50" /> : undefined}
                </div>
                <div className="h-4 mt-1">
                    {(isSelected) ? <img src={selected} className="h-4 w-4" alt="selected_icon" /> : undefined}
                </div>
            </div>
        </div>
    )
}

export default forwardRef(AvatarMin)