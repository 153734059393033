import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import FaceModel from "./face-model.model";

interface FaceModelState {
  faceModels: FaceModel[];
  setFaceModels: (faceModels: FaceModel[]) => void;
  getFaceModel: (id: string) => FaceModel | null;
}

export const useFaceModelStore = create<FaceModelState>()(
  devtools(
    persist(
      (set, get) => ({
        faceModels: [],
        setFaceModels: (faceModels) =>
          set(() => {
            return { faceModels };
          }),
        getFaceModel: (id) => get().faceModels.find((fm) => fm.id === id) || null,
      }),
      {
        name: "face-models-storage",
      }
    )
  )
);
