import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  onAuthStateChanged,
  signOut as signOutInternal,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail as sendPasswordResetEmailInternal,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { setCustomerWithUid, unsetCustomer } from "../domains/customer/customer.service";
import { getFunctions, httpsCallable } from "firebase/functions";
import { setSubscriptionWithCustomerUid, unsetSubscription } from "../domains/subscription/subscription.service";
import { setAvatarsWithCustomerUid, unsetAvatars } from "../domains/avatar/avatar.service";
import { unsetJobs } from "../domains/job/job.service";
import { fetchAndActivate, getRemoteConfig, getValue } from "firebase/remote-config";
import { setFaceModels } from "../domains/face-model/face-model.service";
import { setAudioModels } from "../domains/audio-model/audio-model.service";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { setCustomFaceModelsWithCustomerUid, unsetCustomFaceModels } from "../domains/custom-face-model/custom-face-model.service";
import { setAnalyticsUserId, setAnalyticsEmail, trackEvent } from "./amplitude.service";

console.log("process.env.REACT_APP_ENV", process.env.REACT_APP_ENV);
const firebaseConfig =
  process.env.REACT_APP_ENV === "production"
    ? {
        apiKey: "AIzaSyAEO-ZSATnwCi0KCCYJWmeWgS9qm_ygk1Y",
        authDomain: "otheryou.firebaseapp.com",
        projectId: "otheryou",
        storageBucket: "otheryou.appspot.com",
        messagingSenderId: "918554030275",
        appId: "1:918554030275:web:de919fc617a8bf33ddb276",
        measurementId: "G-RGMN8SF25D",
      }
    : {
        apiKey: "AIzaSyB2FED8AXVpuG0eUEzkbh3lt-EA-xyk_ZM",
        authDomain: "otheryou-staging.firebaseapp.com",
        projectId: "otheryou-staging",
        storageBucket: "otheryou-staging.appspot.com",
        messagingSenderId: "209892254976",
        appId: "1:209892254976:web:d3c293dcc0a15870b6bfe5",
        measurementId: "G-PH1VKVJDB1",
      };

export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const functions = getFunctions(app);
export const remoteConfig = getRemoteConfig(app);
export const storage = getStorage(app);
remoteConfig.settings.minimumFetchIntervalMillis = process.env.NODE_ENV === "production" ? 36000000 : 10000;
remoteConfig.defaultConfig = {
  face_models: "[]",
};
fetchAndActivate(remoteConfig).then(() => {
  const stringFaceModels = getValue(remoteConfig, "face_models").asString();
  const stringAudioModels = getValue(remoteConfig, "audio_models").asString();
  setFaceModels(stringFaceModels);
  setAudioModels(stringAudioModels);
});

const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();

onAuthStateChanged(auth, (user) => {
  if (user) {
    const uid = user.uid;
    setCustomerWithUid(uid);
    setSubscriptionWithCustomerUid(uid);
    setAvatarsWithCustomerUid(uid);
    setCustomFaceModelsWithCustomerUid(uid);
    setAnalyticsUserId(uid);
    if (user?.email) setAnalyticsEmail(user.email);
  } else {
    unsetCustomer();
    unsetSubscription();
    unsetAvatars();
    unsetJobs();
    unsetCustomFaceModels();
    trackEvent("signOut");
  }
});

export function signInWithGoogle() {
  signInWithPopup(auth, googleProvider).catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    const email = error.customData.email;
    const credential = GoogleAuthProvider.credentialFromError(error);
    console.log("error", errorCode, errorMessage, email, credential);
  });
}

export function sendPasswordResetEmail(email: string) {
  return sendPasswordResetEmailInternal(auth, email);
}

export function signOut() {
  return signOutInternal(auth);
}

export function signInWithApple() {
  alert("not implemented yet");
}
export function signInWithMagicLink(email: string) {
  alert("not implemented yet");
}
export async function signInWithMailAndPassword(email: string, password: string) {
  let isAuth: boolean = await createUserWithEmailAndPassword(auth, email, password)
    .then(() => true)
    .catch(() => false);
  if (!isAuth)
    isAuth = await signInWithEmailAndPassword(auth, email, password)
      .then(() => true)
      .catch(() => false);
  if (!isAuth) alert("There was an error signing in. Please try again.");
}
export function getHttpsCallableFunction(functionName: string) {
  return httpsCallable(functions, functionName);
}

export async function uploadFile(file: File, path: string) {
  const storageRef = ref(storage, path);
  await uploadBytes(storageRef, file);
  const url = await getDownloadURL(storageRef);
  return url;
}

export async function handleMagicLink() {
  console.log("handleMagicLink");
  if (isSignInWithEmailLink(auth, window.location.href)) {
    const urlParams = new URLSearchParams(window.location.search);
    const encodedEmail = urlParams.get("authEmail");
    if (!encodedEmail) return;
    const email = decodeURIComponent(encodedEmail);

    await signInWithEmailLink(auth, email, window.location.href).catch((error) => {
      console.log("Unable to magiclink", error);
      return null;
    });
  }
}
