import React, { useRef, useState } from "react";
import MainButton from "./MainButton";
import Swal from "sweetalert2";
import { extractImageFromVideo } from "../services/media-manipulation";
import { trackEvent } from "../services/amplitude.service";

interface VideoUploaderProps {
  onUpload: (file: File, thumbnail: File, videoDurationSeconds: number) => void;
  onPreview?: (file: File | null) => void;
  isLoading?: boolean;
}

function VideoUploader({ onUpload, isLoading = false, onPreview = () => null }: VideoUploaderProps) {
  const [preview, setPreview] = useState(null);
  const [file, setFile] = useState<File | null>(null);
  const [creditsCost, setCreditsCost] = useState<number>(0);
  const [duration, setDuration] = useState<number>(0);
  const fileInputRef = useRef<any>();
  const videoElementRef = useRef<HTMLVideoElement>(null);

  const handleVideoChange = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      setFile(file);
      const reader: any = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
        onPreview(file);
      };
      reader.readAsDataURL(file);
    }
  };
  const onVideoMetadataLoaded = (e: any) => {
    const duration = e.target.duration;
    if (duration > 180) {
      Swal.fire({
        title: "Video duration too long!",
        text: `Video duration is too long. Maximum video duration: 3 minutes. During beta, we are limiting the video duration to 3 minutes.`,
        color: "#000",
        buttonsStyling: false,
        icon: "warning",
        iconColor: "#000",
        showCancelButton: false,
        confirmButtonText: "Understood!",
        customClass: {
          confirmButton: "bg-black px-4 py-3 rounded-[15px] text-white font-semibold mr-2 text-sm",
          cancelButton: "bg-[#ababab] px-4 py-3 text-sm rounded-[15px] text-white font-semibold",
          popup: "rounded-[20px]",
        },
      });
      setFile(null);
      setPreview(null);
      onPreview(null);
      fileInputRef.current.value = null;
      return;
    }
    if (duration > 30) {
      Swal.fire({
        title: "Your video duration is long!",
        text: `You should keep it under 30 seconds. If the result not meet your expectations, you will use a lot of credits. You should consider to split your video in multiple parts.`,
        color: "#000",
        buttonsStyling: false,
        icon: "question",
        iconColor: "#000",
        showCancelButton: true,
        confirmButtonText: "I want to use it anyway!",
        customClass: {
          confirmButton: "bg-black px-4 py-3 rounded-[15px] text-white font-semibold mr-2 text-sm",
          cancelButton: "bg-[#ababab] px-4 py-3 text-sm rounded-[15px] text-white font-semibold",
          popup: "rounded-[20px]",
        },
      }).then((result) => {
        if (result.isDismissed) {
          setFile(null);
          setPreview(null);
          onPreview(null);
          fileInputRef.current.value = null;
          return;
        }
      });
    }
    setDuration(duration);
    const creditsCost = Math.ceil(duration);
    setCreditsCost(creditsCost);
  };

  const triggerFileSelectPopup = () => {
    trackEvent("VideoUploader_Click_UploadVideo");
    fileInputRef?.current.click();
  };

  const uploadVideo = async () => {
    if (isLoading) return;
    if (!file) return;
    if (!videoElementRef.current) return;
    trackEvent("VideoUploader_Click_CreateNewVideo");
    const thumbnail = await extractImageFromVideo(videoElementRef.current);
    if (!thumbnail) return;
    Swal.fire({
      title: "Do you confirm?",
      text: `This operation will use ${creditsCost} credits.`,
      color: "#000",
      icon: "question",
      buttonsStyling: false,
      iconColor: "#000",
      showCancelButton: true,
      confirmButtonText: "Yes, create it!",
      customClass: {
        confirmButton: "bg-black px-4 py-3 rounded-[15px] text-white font-semibold mr-2 text-sm",
        cancelButton: "bg-[#ababab] px-4 py-3 text-sm rounded-[15px] text-white font-semibold",
        popup: "rounded-[20px]",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        onUpload(file, thumbnail, duration);
      }
    });
  };

  return (
    <div className="flex-shrink flex flex-col items-center w-full max-h-full">
      {preview && (
        <div className="w-full h-full">
          <video
            className="rounded-[20px] h-video-on-video-uploader max-h-video-on-video-uploader  bg-black w-full"
            src={preview}
            controls
            onLoadedMetadata={onVideoMetadataLoaded}
            ref={videoElementRef}
          />
        </div>
      )}
      <div className="w-full flex justify-center flex-shrink">
        <input type="file" accept="video/mp4,video/webm,video/ogg" onChange={handleVideoChange} ref={fileInputRef} className="hidden" />
        <MainButton
          text={preview ? "Create new video" : "Upload video"}
          subtext={preview ? creditsCost + " credits" : undefined}
          className="w-full lg:w-[300px] my-4"
          isDisabled={false}
          isLoading={isLoading}
          onClick={preview ? uploadVideo : triggerFileSelectPopup}
        />
      </div>
    </div>
  );
}

export default VideoUploader;
