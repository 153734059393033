import React, { useEffect, useState } from "react";
import { ReactComponent as CheckSvg } from "../assets/icons/check.svg";
import { ReactComponent as CrossSvg } from "../assets/icons/cross.svg";
import example1 from "../assets/images/example1.png";
import example2 from "../assets/images/example2.png";
import example3 from "../assets/images/example3.png";
import AvatarMin from "../components/AvatarMin";
import VideoUploader from "../components/VideoUploader";
import Avatar from "../domains/avatar/avatar.model";
import { useAvatarStore } from "../domains/avatar/avatar.store";
import useQuery from "../hooks/useQuery";
import { useCustomerStore } from "../domains/customer/customer.store";
import { createJob } from "../domains/job/job.service";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { trackEvent } from "../services/amplitude.service";

function CreateVideo() {
  const { avatars } = useAvatarStore();
  const { customer } = useCustomerStore();
  const { avatarId } = useQuery();
  const navigate = useNavigate();
  const [selectedAvatar, setSelectedAvatar] = React.useState<Avatar | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPreview, setIsPreview] = useState<boolean>(false);
  const [isSpeaking, setIsSpeaking] = useState<boolean>(true);
  const [genderVoice, setGenderVoice] = useState<"male" | "female">("male");
  useEffect(() => {
    setSelectedAvatar(avatars.find((avatar) => avatar.id === avatarId) ?? null);
  }, [avatarId, avatars]);

  const uploadVideoAndCreateJob = async (video: File, thumbnail: File, videoDurationSeconds: number) => {
    if (!customer || !selectedAvatar) return;
    setIsLoading(true);
    const job = await createJob(customer.uid, video, thumbnail, selectedAvatar, videoDurationSeconds, isSpeaking, genderVoice);
    await Swal.fire({
      title: "Job created!",
      text: `Your video is processing. You will receive an email when it's ready. Estimated time: ${Math.ceil((videoDurationSeconds * 35) / 60) + 5} minutes.`,
      color: "#000",
      buttonsStyling: false,
      iconColor: "#000",
      showCancelButton: false,
      confirmButtonText: "Understood!",
      customClass: {
        confirmButton: "bg-black px-4 py-3 rounded-[15px] text-white font-semibold mr-2 text-sm",
        cancelButton: "bg-[#ababab] px-4 py-3 text-sm rounded-[15px] text-white font-semibold",
        popup: "rounded-[20px]",
      },
    });
    trackEvent("CreateVideo_Event_JobCreated", { jobId: job.id, avatarId: selectedAvatar.id });
    navigate("/dashboard?avatarId=" + selectedAvatar.id);
  };

  const onVideoPreview = async (file: File | null) => {
    if (!file) return;
    trackEvent("CreateVideo_Event_PreviewVideo");
    setIsPreview(file !== null);
  };

  return (
    <div className={`h-inner w-screen`}>
      <div className="px-3 md:px-7 pt-6 h-inner w-screen max-h-innerflex flex flex-col">
        <div className="w-full">
          <div className="text-xl font-bold mr-[50px] mb-2">Video creation</div>
          <div className="text-base font-bold mb-2">Avatar selected</div>
          {selectedAvatar ? <AvatarMin avatar={selectedAvatar} /> : undefined}
          {!isPreview ? (
            <div>
              <div className="text-base font-bold">For best results</div>
              <div className="text-sm">
                Video with 1 person, no glasses or accessories, no face turned to the side. No face too zoomed in. <b>Maximum video duration: 3 minutes.</b>
              </div>
              <div className="flex gap-3 mt-3">
                <div>
                  <img src={example1} alt="bad_example_1" className="w-[200px] z-0" />
                  <div className="rounded-full w-8 h-8 bg-red-500 flex items-center justify-center relative -top-4 z-20 left-[calc(50%_-_16px)]">
                    <CrossSvg className="w-4 h-4 fill-white" />
                  </div>
                </div>
                <div>
                  <img src={example2} alt="bad_example_2" className="w-[200px] z-0" />
                  <div className="rounded-full w-8 h-8 bg-red-500 flex items-center justify-center relative -top-4 z-10 left-[calc(50%_-_16px)]">
                    <CrossSvg className="w-4 h-4 fill-white" />
                  </div>
                </div>
                <div>
                  <img src={example3} alt="good_example_1" className="w-[200px] z-0" />
                  <div className="rounded-full w-8 h-8 bg-green-500 flex items-center justify-center relative -top-4 z-10 left-[calc(50%_-_16px)]">
                    <CheckSvg className="w-4 h-4 fill-white" />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="mb-2">
              <div>
                <div className="font-bold ">Does the video contain people speaking?</div>
                <div className="flex flex-row items-center">
                  <input className="mr-2 accent-black" type="radio" name="is_speaking" id="is_speaking_yes" value="yes" checked={isSpeaking} onChange={() => setIsSpeaking(true)} />
                  <label htmlFor="is_speaking_yes" className={!isSpeaking ? "text-gray-400" : ""}>
                    yes
                  </label>
                  <input
                    className="ml-6 mr-2 accent-black"
                    type="radio"
                    name="is_speaking"
                    id="is_speaking_no"
                    value="no"
                    checked={!isSpeaking}
                    onChange={() => setIsSpeaking(false)}
                  />
                  <label htmlFor="is_speaking_no" className={isSpeaking ? "text-gray-400" : ""}>
                    no
                  </label>
                </div>
              </div>
              {isSpeaking && (
                <div>
                  <div className="font-bold ">What is the gender of the speaker?</div>
                  <div className="flex flex-row items-center">
                    <input
                      className="mr-2 accent-black"
                      type="radio"
                      name="gender_voice"
                      id="gender_voice_male"
                      value="male"
                      checked={genderVoice === "male"}
                      onChange={() => setGenderVoice("male")}
                    />
                    <label htmlFor="gender_voice_male" className={genderVoice !== "male" ? "text-gray-400" : ""}>
                      male
                    </label>
                    <input
                      className="ml-6 mr-2 accent-black"
                      type="radio"
                      name="gender_voice"
                      id="gender_voice_female"
                      value="female"
                      checked={genderVoice === "female"}
                      onChange={() => setGenderVoice("female")}
                    />
                    <label htmlFor="gender_voice_female" className={genderVoice !== "female" ? "text-gray-400" : ""}>
                      female
                    </label>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        <div className="flex-grow" />
        <div className="w-full flex justify-center">
          <VideoUploader onUpload={uploadVideoAndCreateJob} onPreview={onVideoPreview} isLoading={isLoading} />
        </div>
      </div>
    </div>
  );
}

export default CreateVideo;
