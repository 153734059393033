import { useEffect, useState } from "react";
import DiscreetButton from "../components/DiscreetButton";
import MainButton from "../components/MainButton";
import { trackEvent } from "../services/amplitude.service";
import { sendPasswordResetEmail, signOut } from "../services/firebase.service";
import { gotoCustomerPortal } from "../services/stripe.service";
import { useNavigate } from "react-router-dom";
import { useCustomerStore } from "../domains/customer/customer.store";
import Swal from "sweetalert2";
import Alert from "../components/Alert";

function Settings() {
  const navigate = useNavigate();
  const [isStripePortalLoading, setIsStripePortalLoading] = useState(false);
  const { customer } = useCustomerStore();
  useEffect(() => {
    trackEvent("Settings_Page_Loaded", { userId: customer?.uid });
  }, [customer]);
  const signOutAndRedirect = async () => {
    trackEvent("Settings_Click_SignOut");
    await signOut();
    navigate("/signin");
  };
  const onClickSubscriptionSettings = () => {
    setIsStripePortalLoading(true);
    trackEvent("Settings_Click_SubscriptionSettings");
    gotoCustomerPortal();
  };
  const onChangePasswordClick = () => {
    trackEvent("Settings_Click_ChangePassword");
    if (!customer?.email) return;
    sendPasswordResetEmail(customer?.email).catch((error) => console.log(error));
    Swal.fire({
      title: "Reset Password",
      text: "We've sent you an email with a password reset link.",
      icon: "success",
      confirmButtonText: "Ok",
      confirmButtonColor: "#6A12F3",
    });
  };
  const onContactSupportClick = () => {
    const SUPPORT = "support";
    const HOSTNAME = "otheryou.io";
    const RANDOM_ID = Math.floor(Math.random() * (10000 - 1000 + 1)) + 1000;
    const SUBJECT = encodeURIComponent("Support request ID #" + RANDOM_ID);
    window.location.href = `mailto:${SUPPORT}@${HOSTNAME}?subject=${SUBJECT}`;
  };
  return (
    <div className={`h-inner w-screen`}>
      <div className="pt-6 px-3 md:px-7 h-full w-full flex flex-col items-center">
        <Alert type="success" title="Tip! ">
          To upgrade your <b>Free Trial</b> to a paid subscription and instantly gain additional credits, simply go to <b>Subscription Settings</b>, select <b>Change Plan</b>, and
          choose your preferred plan.
        </Alert>
        <MainButton onClick={onClickSubscriptionSettings} text="Subscription settings" className="mb-4 w-full lg:w-[340px] text" isLoading={isStripePortalLoading} />
        <MainButton onClick={onChangePasswordClick} text="Change Password" className="mb-4 w-full lg:w-[340px] text" />
        <MainButton onClick={onContactSupportClick} text="Contact support" className="mb-4 w-full lg:w-[340px] text" />
        <div className="flex-1" />
        <DiscreetButton onClick={signOutAndRedirect} text="Log out" className="mb-4 text-center" />
      </div>
    </div>
  );
}

export default Settings;
