import { useJobStore } from "./job.store";
import { collection, onSnapshot, Unsubscribe, query, where, deleteDoc, doc, Timestamp, setDoc } from "firebase/firestore";
import { db, uploadFile } from "../../services/firebase.service";
import Job from "./job.model";
import Avatar from "../avatar/avatar.model";

let unsubJob: Unsubscribe | null = null;

export function setJobsWithCustomerUidAndAvatarId(uid: string, avatarId: string) {
  useJobStore.getState().setJobs([]);
  const jobsRef = collection(db, "customers", uid, "jobs");
  const q = query(jobsRef, where("avatarId", "==", avatarId));
  unsubJob = onSnapshot(q, (snapshot) => {
    snapshot.docChanges().forEach((change) => {
      const job: Job = {
        id: change.doc.id,
        createdAt: change.doc.data().createdAt.toDate(),
        updatedAt: change.doc.data().updatedAt.toDate(),
        avatarId: change.doc.data().avatarId,
        status: change.doc.data().status,
        videoDurationSeconds: change.doc.data().videoDurationSeconds,
        jobDurationSeconds: change.doc.data().jobDurationSeconds,
        video: change.doc.data().video,
        thumbnail: change.doc.data().thumbnail,
        avatarAudioModel: change.doc.data().avatarAudioModel,
        avatarFaceUrl: change.doc.data().avatarFaceUrl,
        avatarGenderVoice: change.doc.data().avatarGenderVoice,
        inputUrl: change.doc.data().inputUrl,
        genderVoice: change.doc.data().genderVoice,
        error: change.doc.data().error,
      };
      if (change.type === "added") {
        useJobStore.getState().addJob(job);
      }
      if (change.type === "modified") {
        useJobStore.getState().updateJob(job);
      }
      if (change.type === "removed") {
        useJobStore.getState().removeJob(job);
      }
    });
  });
}

export function unsetJobs() {
  unsubJob?.();
  useJobStore.getState().setJobs([]);
}

export async function deleteJob(customerUid: string, jobId: string) {
  const jobRef = doc(db, "customers", customerUid, "jobs", jobId);
  await deleteDoc(jobRef);
}

export async function createJob(
  customerUid: string,
  video: File,
  thumbnail: File,
  avatar: Avatar,
  videoDurationSeconds: number,
  isSpeaking: boolean,
  genderVoice: "male" | "female"
) {
  const collectionRef = collection(db, "customers", customerUid, "jobs");
  const customFaceModelRef = doc(collectionRef);
  const extension = video.name.split(".").pop();
  const bucketVideoPath = `customers/${customerUid}/jobs/${customFaceModelRef.id}/input.${extension}`;
  const referenceUrl = await uploadFile(video, bucketVideoPath);
  const bucketThumbnailPath = `customers/${customerUid}/jobs/${customFaceModelRef.id}/thumbnail.jpg`;
  const thumbnailUrl = await uploadFile(thumbnail, bucketThumbnailPath);
  const job: Job = {
    id: customFaceModelRef.id,
    createdAt: new Date(),
    updatedAt: new Date(),
    status: "pending",
    avatarId: avatar.id,
    videoDurationSeconds,
    avatarAudioModel: avatar.audioModel,
    avatarFaceUrl: avatar.face,
    avatarGenderVoice: avatar.genderVoice,
    inputUrl: referenceUrl,
    video: "",
    thumbnail: thumbnailUrl,
    jobDurationSeconds: 0,
    genderVoice: isSpeaking ? genderVoice : "no_voice",
    error: "",
  };
  console.log(job);
  await setDoc(customFaceModelRef, {
    createdAt: Timestamp.fromDate(job.createdAt),
    updatedAt: Timestamp.fromDate(job.updatedAt),
    status: job.status,
    avatarId: job.avatarId,
    videoDurationSeconds: job.videoDurationSeconds,
    avatarAudioModel: job.avatarAudioModel,
    avatarFaceUrl: job.avatarFaceUrl,
    inputUrl: job.inputUrl,
    video: job.video,
    thumbnail: job.thumbnail,
    jobDurationSeconds: job.jobDurationSeconds,
    avatarGenderVoice: job.avatarGenderVoice,
    genderVoice: job.genderVoice,
  });
  return job;
}
