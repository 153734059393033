import React from "react";
import { ReactComponent as ProcessingSvg } from "../assets/icons/processing.svg";

interface MainButtonProps {
  text: string;
  subtext?: string;
  className?: string;
  onClick?: () => void;
  isDisabled?: boolean;
  isLoading?: boolean;
}

export function MainButton({ text, subtext, className = "", onClick = undefined, isDisabled = false, isLoading = false }: MainButtonProps) {
  return (
    <div
      onClick={!isLoading && !isDisabled ? onClick : undefined}
      className={`bg-black h-[62px] rounded-[20px] text-white flex justify-center items-center ${className} ${
        isDisabled ? "opacity-30" : "active:bg-[#4e4e4e]"
      }`}
    >
      {isLoading ? (
        <ProcessingSvg className="w-5 h-5 spin fill-white" />
      ) : (
        <div>
          <div className="text-xl font-semibold text-center">{text}</div>
          {subtext && <div className="text-center text-xs text-gray-300">{subtext}</div>}
        </div>
      )}
    </div>
  );
}

export default MainButton;
