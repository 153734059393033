import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import Avatar from "./avatar.model";

interface AvatarState {
  avatars: Avatar[];
  setAvatars: (avatars: Avatar[]) => void;
  addAvatar: (avatar: Avatar) => void;
  removeAvatar: (avatar: Avatar) => void;
  updateAvatar: (avatar: Avatar) => void;
}

export const useAvatarStore = create<AvatarState>()(
  devtools(
    persist(
      (set) => ({
        avatars: [],
        setAvatars: (avatars) =>
          set(() => {
            return { avatars: avatars.sort((a, b) => (a > b ? 1 : -1)) };
          }),
        addAvatar: (avatar) =>
          set((state) => {
            return { avatars: state.avatars.some((a) => a.id === avatar.id) ? state.avatars : [...state.avatars, avatar] };
          }),
        removeAvatar: (avatar) =>
          set((state) => {
            return { avatars: state.avatars.filter((a) => a.id !== avatar.id) };
          }),
        updateAvatar: (avatar) =>
          set((state) => {
            return { avatars: state.avatars.map((a) => (a.id === avatar.id ? avatar : a)) };
          }),
      }),
      {
        name: "avatars-storage",
      }
    )
  )
);
