import React from 'react'

function PageNotFound() {
    return (
        <div className="flex flex-col items-center justify-center h-inner w-screen">
            <div className='text-xl font-bold mb-2'>404 not found</div>
            <div className="text-sm mb-4">The page you're trying to reach does no exist.</div>
        </div>
    )
}

export default PageNotFound