import React from "react";

interface InfoBadgeProps {
  children: React.ReactNode;
  textColor?: string;
  bgColor?: string;
  className?: string;
}

function InfoBadge({ children, textColor = "#929292", bgColor = "#ffffff70", className = "" }: InfoBadgeProps) {
  return (
    <div className={"z-20 " + className}>
      <div className="relative group cursor-pointer">
        <div
          className="rounded-full w-5 h-5 flex justify-center items-center font-bold text-xs"
          style={{ color: textColor, backgroundColor: bgColor }}
        >
          ?
        </div>
        <div className="z-20 text-left invisible group-hover:visible text-xs fixed left-0 mx-2 sm:absolute sm:top-2 sm:w-72 shadow-md bg-gray-100 w-full  text-gray-500 px-2 py-2 rounded-[4px]">
          {children}
        </div>
      </div>
    </div>
  );
}

export default InfoBadge;
