import AudioModel from "./audio-model.model";
import { useAudioModelStore } from "./audio-model.store";

export function setAudioModels(stringAudioModels: string): void {
  const rawAudioModels: any[] = JSON.parse(stringAudioModels) as any[];
  const audioModels: AudioModel[] = rawAudioModels.map((rawAudioModel) => {
    const audioModel: AudioModel = {
      id: rawAudioModel.id,
      audioUrl: rawAudioModel.audioUrl,
      category: rawAudioModel.category,
      genderVoice: rawAudioModel.genderVoice,
    };
    return audioModel;
  });
  useAudioModelStore.getState().setAudioModels(audioModels);
}
