import React, { useState } from "react";
import { LightBulbIcon, MegaphoneIcon, XMarkIcon } from "@heroicons/react/20/solid";

interface AlertProps {
  type?: "error" | "warning" | "info" | "announcement" | "success";
  children: React.ReactNode;
  title?: string;
}

const bgColors = {
  error: "bg-red-100",
  warning: "bg-yellow-100",
  info: "bg-gray-100",
  announcement: "bg-indigo-100",
  success: "bg-blue-100",
};

const textColors = {
  error: "text-red-500",
  warning: "text-yellow-500",
  info: "text-gray-500",
  announcement: "text-indigo-500",
  success: "text-blue-500",
};

const bgColorsDark = {
  error: "bg-red-500",
  warning: "bg-yellow-500",
  info: "bg-gray-500",
  announcement: "bg-indigo-500",
  success: "bg-blue-500",
};

const icons = {
  error: <LightBulbIcon className="h-5 inline text-red-400" />,
  warning: <LightBulbIcon className="h-5 inline text-yellow-400" />,
  info: <LightBulbIcon className="h-5 inline text-gray-400" />,
  announcement: <MegaphoneIcon className="h-5 inline text-indigo-400" />,
  success: <LightBulbIcon className="h-5 inline text-blue-400" />,
};
export default function Alert({ type = "info", children, title = undefined }: AlertProps) {
  const [visible, setVisible] = useState(true);
  return (
    <div className={`relative mb-2 ${visible ? "visible" : "hidden"}`}>
      <XMarkIcon
        className={`h-5 w-5 absolute -top-3 right-1 cursor-pointer text-white opacity-40 ${bgColorsDark[type]} p-[2px] rounded-full focus:opacity-20`}
        onClick={() => setVisible(false)}
      />
      <div className={`mb-2 text-sm rounded-[5px] px-2 py-3 ${bgColors[type]} ${textColors[type]}`}>
        {icons[type]}
        <b className="bold">{title || type.charAt(0).toUpperCase() + type.slice(1) + "! "}</b>
        {children}
      </div>
    </div>
  );
}
