import { useSubscriptionStore } from "./subscription.store";
import { onSnapshot, Unsubscribe, collection } from "firebase/firestore";
import { db } from "../../services/firebase.service";

let unsubSubscription: Unsubscribe | null = null;

export async function setSubscriptionWithCustomerUid(uid: string) {
    const collectionRef = collection(db, "customers", uid, "subscriptions")
    unsubSubscription = onSnapshot(collectionRef, (snapshot) => {
        if (snapshot.docs.length === 0) useSubscriptionStore.getState().setSubscription({
            id: "no-subscription",
            created: new Date(),
            status: "no-subscription",
            plan: "no-plan",
            priceId: "no-price-id",
        })
        snapshot.docChanges().forEach((change) => {
            if (change.type === "added" || change.type === "modified") {
                const SubscriptionData = change.doc.data()
                useSubscriptionStore.getState().setSubscription({
                    id: change.doc.id,
                    created: SubscriptionData?.created.toDate(),
                    status: SubscriptionData?.status,
                    plan: SubscriptionData?.items[0].price.product.name,
                    priceId: SubscriptionData?.items[0].price.id,
                })
            }
            if (change.type === "removed") {
                useSubscriptionStore.getState().setSubscription({
                    id: "no-subscription",
                    created: new Date(),
                    status: "no-subscription",
                    plan: "no-plan",
                    priceId: "no-price-id",
                })
            }
        });
    });
}

export function unsetSubscription() {
    unsubSubscription?.();
    useSubscriptionStore.getState().setSubscription(null)
}