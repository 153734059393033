import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ButtonAdd from "../components/ButtonAdd";
import FaceModel from "../components/FaceModel";
import MainButton from "../components/MainButton";
import { useFaceModelStore } from "../domains/face-model/face-model.store";
import { useCustomFaceModelStore } from "../domains/custom-face-model/custom-face-model.store";
import { AdjustmentsHorizontalIcon } from "@heroicons/react/24/outline";
import AvatarCreationFaceFilter, { AgeFilter, SpecialFilter, GenderFilter } from "../components/AvatarCreationFaceFilter";
import { trackEvent } from "../services/amplitude.service";
import { ReactComponent as AddSvg } from "../assets/icons/add.svg";
// import Alert from "../components/Alert";
// import { FixedSizeGrid as Grid } from 'react-window';
// import { useMediaQuery } from 'react-responsive'

function AvatarCreationFace() {
  const { customFaceModels } = useCustomFaceModelStore();
  const { faceModels } = useFaceModelStore();
  const [selectedFaceModelId, setSelectedFaceModelId] = useState<string | null>(null);
  const [isFilterDisplayed, setIsFilterDisplayed] = useState(false);
  const [genderFilter, setGenderFilter] = useState<GenderFilter>("all");
  const [ageFilter, setAgeFilter] = useState<AgeFilter>("all");
  const [specialFilter, setSpecialFilter] = useState<SpecialFilter>("all");

  const navigate = useNavigate();
  // const isSm = useMediaQuery({ query: '(min-width: 640px)' })
  // const isMd = useMediaQuery({ query: '(min-width: 768px)' })
  // const isLg = useMediaQuery({ query: '(min-width: 1024px)' })
  // const isXl = useMediaQuery({ query: '(min-width: 1280px)' })

  const onFilterChange = (newGenderFilter: GenderFilter, newAgeFilter: AgeFilter, newSpecialFilter: SpecialFilter) => {
    trackEvent("AvatarCreationFace_Click_Filter", { newGenderFilter, newAgeFilter, newSpecialFilter });
    if (newGenderFilter !== genderFilter) setGenderFilter(newGenderFilter);
    if (newAgeFilter !== ageFilter) setAgeFilter(newAgeFilter);
    if (newSpecialFilter !== specialFilter) setSpecialFilter(newSpecialFilter);
  };

  const isInAgeFilter = (age: number) => {
    if (ageFilter === "all") return true;
    if (ageFilter === "youth") return age <= 20;
    if (ageFilter === "adult") return age > 20 && age < 60;
    if (ageFilter === "senior") return age >= 60;
  };

  const onAddPictureClick = () => {
    trackEvent("AvatarCreationFace_Click_AddPicture");
    navigate("/avatar-creation-custom");
  };

  const filteredFaceModels = [...faceModels].filter((faceModel) => {
    const isGenderOk = genderFilter === "all" ? true : faceModel.gender === genderFilter;
    const isAgeOk = isInAgeFilter(faceModel.age);
    const isSpecialOk = specialFilter === "all" ? true : faceModel.special === specialFilter;
    return isGenderOk && isAgeOk && isSpecialOk;
  });
  // const FaceModelCell = ({ columnIndex: col, rowIndex: row, style }: any) => {
  //     const faceModel = faceModels[row * 3 + col];
  //     console.log(col, row, faceModel)
  //     if (!faceModel) {
  //         return <div></div>;
  //     }
  //     return (
  //         <div style={{ ...style }}>
  //             <FaceModel faceModel={faceModel} isSelected={faceModel.id === selectedFaceModelId} onClick={() => setSelectedFaceModelId(faceModel.id)} />
  //         </div>
  //     )
  // };

  const onClickContinue = () => {
    if (!selectedFaceModelId) return;
    trackEvent("AvatarCreationFace_Click_Continue", { selectedFaceModelId });
    navigate(`/avatar-creation-audio?faceId=${selectedFaceModelId}`);
  };

  const onClickFaceModel = (faceModelId: string) => {
    setSelectedFaceModelId(faceModelId);
    trackEvent("AvatarCreationFace_Click_FaceModel", { faceModelId });
  };

  return (
    <div className={`h-inner w-screen`}>
      <div className="px-3 md:px-7 pt-6 w-full h-full flex flex-col">
        <div className="text-xl font-bold mr-[50px]">Avatar creation (1/2) - Picture selection</div>
        {/* <Alert type="info" title="Tip!">
          Choose an avatar that look a bit like you (skin, hair, etc.). You’ll get better result.
        </Alert>
        <Alert type="announcement" title="Custom avatar are coming soon.">
          You'll be able to create custom avatars based on picture, celebrity or prompt.
        </Alert> */}

        <div className="flex justify-center mb-4 active:opacity-5" onClick={() => setIsFilterDisplayed(!isFilterDisplayed)}>
          <button className="bg-black text-white flex items-center rounded-sm px-4 py-2">
            <AdjustmentsHorizontalIcon className="w-6 h-6 text-black-500  mr-3" />
            <div>Filter</div>
          </button>
        </div>
        <div className={`mb-6  ${isFilterDisplayed ? "block" : "hidden"}`}>
          <AvatarCreationFaceFilter onFilterChange={onFilterChange} />
        </div>

        <div className="flex-1 overflow-y-auto">
          {/* <Grid
                        key={faceModels.length}
                        columnCount={3}
                        rowCount={faceModels.length / 3}
                        columnWidth={100}
                        rowHeight={100}
                        width={300}
                        height={300}

                    >
                        {FaceModelCell}
                    </Grid> */}
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-8 gap-4">
            <div onClick={onAddPictureClick} className="bg-black text-white flex flex-col items-center justify-between rounded-[20px] active:opacity-50">
              <div className="h-7 mt-5" />
              <AddSvg className="w-12 h-12" />
              <div className="h-7 mb-5 font-medium">Upload Picture</div>
            </div>
            {filteredFaceModels.map((faceModel, i) => (
              <FaceModel onClick={() => onClickFaceModel(faceModel.id)} faceModel={faceModel} key={faceModel.id + i} isSelected={selectedFaceModelId === faceModel.id} />
            ))}
          </div>
        </div>
        <div className="w-full flex justify-center">
          <MainButton text="Continue" className="w-full lg:w-[300px] my-4" isDisabled={selectedFaceModelId === null} onClick={onClickContinue} />
        </div>
      </div>
    </div>
  );
  // eslint-disable-next-line
  return (
    <div className={`h-inner w-screen`}>
      <div className="px-3 md:px-7 pt-6 w-full h-full flex flex-col">
        <div className="text-xl font-bold mr-[50px]">Avatar creation (1/2) - Picture selection</div>

        <div className="flex-1 overflow-y-auto">
          <div className="text-base font-bold mb-2">Custom avatar (looking like you)</div>
          <div className="whitespace-nowrap overflow-x-auto h-[180px] flex items-center">
            {customFaceModels.map((faceModel) => (
              <FaceModel
                onClick={() => faceModel.faceUrl && setSelectedFaceModelId(faceModel.id)}
                faceModel={faceModel}
                key={faceModel.id}
                className="inline-block mr-4"
                isSelected={selectedFaceModelId === faceModel.id}
              />
            ))}
            <ButtonAdd className="mx-[25px] mb-[20px]" onClick={() => navigate("/avatar-creation-custom")} />
          </div>
          <div className="text-base font-bold mb-2 mt-6">Celebrities</div>
          <div className="whitespace-nowrap overflow-x-auto h-[180px] flex">
            {faceModels.map((faceModel) => (
              <FaceModel
                onClick={() => setSelectedFaceModelId(faceModel.id)}
                faceModel={faceModel}
                key={faceModel.id}
                className="inline-block mr-4"
                isSelected={selectedFaceModelId === faceModel.id}
              />
            ))}
          </div>
          <div className="text-base font-bold mb-2">Young</div>
          <div className="whitespace-nowrap overflow-x-auto h-[180px] flex">
            {faceModels.map((faceModel) => (
              <FaceModel
                onClick={() => setSelectedFaceModelId(faceModel.id)}
                faceModel={faceModel}
                key={faceModel.id}
                className="inline-block mr-4"
                isSelected={selectedFaceModelId === faceModel.id}
              />
            ))}
          </div>
          <div className="text-base font-bold mb-2">Elderly</div>
          <div className="whitespace-nowrap overflow-x-auto h-[180px] flex">
            {faceModels.map((faceModel) => (
              <FaceModel
                onClick={() => setSelectedFaceModelId(faceModel.id)}
                faceModel={faceModel}
                key={faceModel.id}
                className="inline-block mr-4"
                isSelected={selectedFaceModelId === faceModel.id}
              />
            ))}
          </div>
        </div>
        <div className="w-full flex justify-center">
          <MainButton
            text="Continue"
            className="w-full lg:w-[300px] my-4"
            isDisabled={selectedFaceModelId === null}
            onClick={() => {
              if (selectedFaceModelId) navigate("/avatar-creation-audio?faceId=" + selectedFaceModelId);
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default AvatarCreationFace;
