import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
// import apple from "../assets/icons/apple.svg";
import google from "../assets/icons/google.svg";
import { ReactComponent as ProcessingSvg } from "../assets/icons/processing.svg";
import backgroundMobile from "../assets/images/background-mobile.png";
import background from "../assets/images/background.png";
import DiscreetButton from "../components/DiscreetButton";
import { useCustomerStore } from "../domains/customer/customer.store";
import { trackEvent } from "../services/amplitude.service";
import { sendPasswordResetEmail, signInWithGoogle, signInWithMailAndPassword } from "../services/firebase.service";
import { validateEmail, validatePassword } from "../services/string.service";
import Swal from "sweetalert2";
export default function SignIn() {
  const { customer } = useCustomerStore();
  const navigate = useNavigate();
  const isLargeScreen = useMediaQuery({ minWidth: 1024 });
  const backgroundImage = isLargeScreen ? background : backgroundMobile;
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [isEmailValid, setIsEmailValid] = useState<boolean>(false);
  const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false);
  const [isEmailConnecting, setIsEmailConnecting] = useState<boolean>(false);

  const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setIsEmailValid(validateEmail(e.target.value));
  };
  const onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    setIsPasswordValid(validatePassword(e.target.value));
  };
  const onSignInWithEmail = async () => {
    if (isEmailConnecting) return;
    if (!isEmailValid) return;
    if (!isPasswordValid) return;
    setIsEmailConnecting(true);
    trackEvent("SignIn_Click_SignInWithEmail");
    signInWithMailAndPassword(email, password);
  };
  const onForgotPasswordClick = () => {
    trackEvent("SignIn_Click_ForgotPassword");
    if (!isEmailValid) return;
    sendPasswordResetEmail(email).catch((error) => console.log(error));
    Swal.fire({
      title: "Reset Password",
      text: "If registered, an email with a password reset link has been sent to you.",
      icon: "success",
      confirmButtonText: "Ok",
      confirmButtonColor: "#6A12F3",
    });
  };
  const onSignInWithGoogle = () => {
    trackEvent("SignIn_Click_SignInWithGoogle");
    signInWithGoogle();
  };
  // const onSignInWithApple = () => {
  //   trackEvent("SignIn_Click_SignInWithApple");
  // };
  useEffect(() => {
    if (customer) navigate("/dashboard");
  }, [customer, navigate]);
  return (
    <div className={`h-inner w-screen`}>
      <div style={{ backgroundImage: `url(${backgroundImage})` }} className={`flex justify-center items-center w-screen h-inner bg-center bg-auto bg-no-repeat lg:drop-shadow-lg`}>
        <div className="w-96 bg-[#fcfcfcbb] p-8 rounded-[20px] drop-shadow-lg lg:drop-shadow-none">
          <h1 className="mb-6 text-center text-3xl font-bold">
            Other<span className="text-[#6A12F3] font-extrabold">You</span>
          </h1>
          <p className="text-center mb-10">We'll sign you in or create an account if you don't have one yet.</p>
          <div
            onClick={onSignInWithGoogle}
            className="cursor-pointer flex w-full mb-3 justify-center items-center rounded-lg bg-white px-3 py-2 text-sm leading-6 text-black hover:bg-gray-50 border border-solid border-gray-200"
          >
            <img className="h-6 mr-3" src={google} alt="google-icon" />
            Continue with Google
          </div>
          {/* <div
            onClick={onSignInWithApple}
            className="cursor-pointer flex w-full justify-center items-center rounded-lg bg-white px-3 py-2 text-sm leading-6 text-black hover:bg-gray-50 border border-solid border-gray-200"
          >
            <img className="h-6 mr-3" src={apple} alt="apple-icon" />
            Continue with Apple
          </div> */}
          <div className="flex my-6 items-center">
            <div className="w-full border-t border-gray-200" />
            <span className="px-6 text-gray-900">or</span>
            <div className="w-full border-t border-gray-200" />
          </div>
          <div className="mb-3">
            <input
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              required
              placeholder="name@workemail.com"
              onChange={onEmailChange}
              className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
            <div className="text-xs text-gray-400 mb-2 mt-1">{email && !isEmailValid && "Please enter a valid email address"}</div>
            {email && (
              <div>
                <input
                  id="password"
                  name="password"
                  type="password"
                  required
                  placeholder="password"
                  onChange={onPasswordChange}
                  className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                <div className="text-xs text-gray-400 mb-2 mt-1">
                  {password && !isPasswordValid && "Password must be at least 8 characters long and at least one number and one letter"}
                </div>
              </div>
            )}
          </div>
          <div
            onClick={onSignInWithEmail}
            className={`cursor-pointer flex w-full justify-center items-center rounded-lg px-3 py-2 text-sm leading-6 text-white border border-solid border-gray-200 ${
              isEmailValid && isPasswordValid ? "bg-[#6A12F3] active:opacity-50" : "bg-[#AFB6ED]"
            }`}
          >
            {isEmailConnecting ? <ProcessingSvg className="w-6 h-6 z-0 spin fill-white opacity-50" /> : "Continue with email"}
          </div>
          {email && <DiscreetButton className="mt-4 text-center text-blue-600 underline" text="Forgot Password" onClick={() => onForgotPasswordClick()} />}
          <div className="mt-4 text-xs text-center text-gray-500">
            By logging in, you agree to the <br />{" "}
            <a href="/terms" className="text-blue-600 underline">
              Terms of Use
            </a>{" "}
            and{" "}
            <a href="/privacy" className="text-blue-600 underline">
              Privacy Policy
            </a>
            .{" "}
          </div>
        </div>
      </div>
    </div>
  );
}
