import React, { useState } from "react";
import Job from "../domains/job/job.model";
import { ReactComponent as ProcessingSvg } from "../assets/icons/processing.svg";
import InfoBadge from "./InfoBadge";
import { trackEvent } from "../services/amplitude.service";
import Swal from "sweetalert2";
import { deleteJob } from "../domains/job/job.service";
import { useCustomerStore } from "../domains/customer/customer.store";
import DiscreetButton from "./DiscreetButton";

interface JobMinProps {
  job: Job;
  className?: string;
  onClick?: () => void;
}

function JobMin({ job, className = "", onClick = undefined }: JobMinProps) {
  const { customer } = useCustomerStore();
  const deleteVideo = () => {
    if (!customer) return;
    trackEvent("JobMin_Click_DeleteVideo", { jobId: job.id });
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      color: "#000",
      icon: "warning",
      buttonsStyling: false,
      iconColor: "#6A12F3",
      showCancelButton: true,
      confirmButtonColor: "#6A12F3",
      cancelButtonColor: "#000",
      confirmButtonText: "Yes, delete it!",
      customClass: {
        confirmButton: "bg-[#6A12F3] px-4 py-3 rounded-[15px] text-white font-semibold mr-2 text-sm",
        cancelButton: "bg-black px-4 py-3 text-sm rounded-[15px] text-white font-semibold",
        popup: "rounded-[20px]",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        trackEvent("JobMin_Click_DeleteVideoConfirm", { jobId: job.id });
        deleteJob(customer?.uid, job.id);
      } else trackEvent("JobMin_Click_DeleteVideoCancel", { jobId: job.id });
    });
  };
  const [isImageLoading, setImageLoading] = useState(true);
  return (
    <div className={"relative " + className} onClick={onClick}>
      {isImageLoading ? (
        <div className="w-full aspect-square lg:w-[200px] lg:h-[200px] rounded-[20px] bg-gray-300 flex items-center justify-center">
          <ProcessingSvg className="w-8 h-8 z-0 spin fill-black opacity-50" />
        </div>
      ) : undefined}
      <div>
        <img
          onLoad={() => setImageLoading(false)}
          className={`w-full h-full aspect-square object-cover lg:w-[200px] lg:h-[200px] rounded-[20px] ${isImageLoading ? "hidden" : "block"}`}
          src={job.thumbnail}
          alt="job_thumbnail"
        />
        {!isImageLoading && job.status === "pending" && (
          <div className="bg-[#ffffff90] w-full h-full lg:w-[200px] lg:h-[200px] rounded-[20px] absolute top-0 left-0 flex items-center justify-center flex-col">
            <div className="text-base font-normal z-10 mt-4 text-center text-black relative">
              Waiting available
              <br />
              AI Engine
              <InfoBadge bgColor="#00000050" textColor="#ffffffdd" className="absolute top-0 -right-6">
                All video processing servers are currently busy. Your video will be processed as soon as an AI engine becomes available.
              </InfoBadge>
            </div>
          </div>
        )}
        {!isImageLoading && job.status === "processing" && (
          <div className="bg-[#ffffff90] w-full h-full lg:w-[200px] lg:h-[200px] rounded-[20px] absolute top-0 left-0 flex items-center justify-center flex-col">
            <ProcessingSvg className="w-[30%] h-[30%] z-0 spin fill-black" />
            <div className="text-2xl font-normal z-10 mt-4">processing</div>
          </div>
        )}
        {!isImageLoading && job.status === "failed" && (
          <div className="bg-[#ffffff90] w-full h-full lg:w-[200px] lg:h-[200px] rounded-[20px] absolute top-0 left-0 flex items-center justify-center flex-col">
            <div className="text-base font-normal z-10 mt-4 text-black text-center relative">
              Error during
              <br />
              processing
              <InfoBadge bgColor="#00000050" textColor="#ffffffdd" className="absolute top-0 -right-6">
                {/* An issue occurred during video processing, see details below. For further assistance, please contact our Support team.
                <div className="text-left text-red-500 mt-2">{job.error}</div> */}
                An issue occurred during video processing. Our team has been notified and an engineer will investigate the issue. You can retry the video.
                <DiscreetButton text="x Delete job" className="mt-2" onClick={deleteVideo} />
              </InfoBadge>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default JobMin;
