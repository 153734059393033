import * as amplitude from "@amplitude/analytics-browser";

let identifyEvent: amplitude.Identify;

export const initAmplitude = () => {
  amplitude.init("d0bdbaad0321cd8fe04488fe3f5054aa");
  identifyEvent = new amplitude.Identify();
};

export const trackEvent = (eventName: string, eventProperties?: any) => {
  amplitude.logEvent(eventName, eventProperties);
  console.log("trackEvent", eventName, eventProperties);
};

export const setUserProperty = (property: string, value: any) => {
  identifyEvent.set(property, value);
  amplitude.identify(identifyEvent);
};

export const setAnalyticsUserId = (userId: string) => {
  amplitude.setUserId(userId);
};

export const setAnalyticsEmail = (email: string) => {
  setUserProperty("email", email);
};
