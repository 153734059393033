import React, { useState } from 'react'
import FaceModelModel from '../domains/face-model/face-model.model'
import selected from "../assets/icons/selected.svg";
import { ReactComponent as ProcessingSvg } from "../assets/icons/processing.svg";
import CustomFaceModel from '../domains/custom-face-model/custom-face-model.model';

interface FaceModelProps {
    faceModel: FaceModelModel | CustomFaceModel,
    className?: string,
    onClick?: () => void,
    isSelected?: boolean
}

function FaceModel({ faceModel, className = "", onClick, isSelected = false }: FaceModelProps) {
    const [isImageLoading, setImageLoading] = useState(true);
    return (
        <div className={'active:opacity-50 ' + className} onClick={onClick} >
            <div className="flex flex-col items-center">
                {
                    isImageLoading
                        ? <div className="rounded-[20px] w-full aspect-square bg-gray-300 flex items-center justify-center">
                            <ProcessingSvg className="w-8 h-8 z-0 spin fill-black opacity-50" />
                        </div>
                        : undefined
                }
                <img
                    className={`rounded-[20px] ${isImageLoading ? 'hidden' : 'block'}`}
                    style={isSelected ? { borderColor: "#6A12F3", borderWidth: 4 } : {}}
                    src={faceModel.faceUrl}
                    alt="face_model"
                    onLoad={() => setImageLoading(false)} />
            </div>
        </div>
    )
    // eslint-disable-next-line
    return (
        <div className={'active:opacity-50 ' + className} onClick={onClick} >
            <div className="flex flex-col items-center h-[170px] w-[150px]">
                {
                    isImageLoading
                        ? <div className="w-[150px] h-[150px] rounded-[20px] bg-gray-300 flex items-center justify-center">
                            <ProcessingSvg className="w-8 h-8 z-0 spin fill-black opacity-50" />
                        </div>
                        : undefined
                }
                <img
                    className={`w-[150px] h-[150px] rounded-[20px] ${isImageLoading ? 'hidden' : 'block'}`}
                    style={isSelected ? { borderColor: "#6A12F3", borderWidth: 4 } : {}}
                    src={faceModel.faceUrl}
                    alt="face_model"
                    onLoad={() => setImageLoading(false)} />
                <div className="h-4 mt-1">
                    {(isSelected) ? <img src={selected} className="h-4 w-4" alt="selected_icon" /> : undefined}
                </div>
            </div>
        </div>
    )
}

export default FaceModel