import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import AvatarMin from "../components/AvatarMin";
import ButtonAdd from "../components/ButtonAdd";
import JobMin from "../components/JobMin";
import MainButton from "../components/MainButton";
import { deleteAvatar } from "../domains/avatar/avatar.service";
import { useAvatarStore } from "../domains/avatar/avatar.store";
import { useCustomerStore } from "../domains/customer/customer.store";
import { setJobsWithCustomerUidAndAvatarId } from "../domains/job/job.service";
import { useJobStore } from "../domains/job/job.store";
import { useSubscriptionStore } from "../domains/subscription/subscription.store";
import useQuery from "../hooks/useQuery";
import { trackEvent } from "../services/amplitude.service";
import Alert from "../components/Alert";
import { handleMagicLink } from "../services/firebase.service";

function Dashboard() {
  const navigate = useNavigate();
  const [selectedAvatarId, setSelectedAvatarId] = React.useState<string | null>(null);
  const { avatarId } = useQuery();
  const selectedAvatarRef = useRef<any>(null);

  const { customer } = useCustomerStore();
  const { subscription } = useSubscriptionStore();
  const { avatars } = useAvatarStore();
  const { jobs } = useJobStore();

  useEffect(() => {
    const startup = async () => {
      await handleMagicLink();
      if (!customer) navigate("/signin");
      if (subscription?.status === "no-subscription" && customer?.credits === 0) navigate("/subscribe");
    };
    startup();
  }, [customer, navigate, subscription]);

  useEffect(() => {
    if (selectedAvatarId === null) return;
    setJobsWithCustomerUidAndAvatarId(customer?.uid!, selectedAvatarId);
  }, [selectedAvatarId, customer]);

  useEffect(() => {
    if (!selectedAvatarId) return;
    trackEvent("Dashboard_Click_Avatar", { ...avatars.filter((a) => a.id === selectedAvatarId)?.[0] });
  }, [selectedAvatarId, avatars]);

  useEffect(() => {
    if (!avatarId) return;
    setSelectedAvatarId(avatarId);
    if (selectedAvatarRef.current) {
      // TODO - re-enable that later, it will be complicated to make it works
      // selectedAvatarRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'end' });
    }
  }, [avatarId]);

  const onClickDeleteAvatar = (avatarId: string) => {
    if (!customer) return;
    trackEvent("Dashboard_Click_DeleteAvatar");
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      color: "#000",
      icon: "warning",
      buttonsStyling: false,
      iconColor: "#6A12F3",
      showCancelButton: true,
      confirmButtonColor: "#6A12F3",
      cancelButtonColor: "#000",
      confirmButtonText: "Yes, delete it!",
      customClass: {
        confirmButton: "bg-[#6A12F3] px-4 py-3 rounded-[15px] text-white font-semibold mr-2 text-sm",
        cancelButton: "bg-black px-4 py-3 text-sm rounded-[15px] text-white font-semibold",
        popup: "rounded-[20px]",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        trackEvent("Dashboard_Click_ConfirmDeleteAvatar");
        deleteAvatar(customer?.uid, avatarId);
        setSelectedAvatarId(null);
      } else {
        trackEvent("Dashboard_Click_CancelDeleteAvatar");
      }
    });
  };

  const onClickAddAvatar = () => {
    trackEvent("Dashboard_Click_AddAvatar");
    navigate("/avatar-creation-face");
  };
  const onClickCreateVideo = () => {
    trackEvent("Dashboard_Click_CreateVideo");
    navigate("/create-video?avatarId=" + selectedAvatarId);
  };
  return (
    <div className={`h-inner w-screen`}>
      {avatars.length === 0 ? (
        <div className="pt-6 px-3 md:px-7 h-full w-full flex flex-col">
          <div className="text-xl font-bold mb-2">Create your first avatar</div>
          <Alert>
            To start using <span className="font-bold">OtherYou</span> click on the
            <span className=" inline-block mx-1 border-black border p-[2px] rounded-[10px]">
              <div className="flex justify-center items-center w-6 h-6 bg-black rounded-[8px] text-white">+</div>
            </span>
            button.
          </Alert>
          {/* <div className="text-sm mb-4">
            To start using{" "}
            <span className="font-bold">
              Other<span className="text-[#6A12F3] font-extrabold">You</span>{" "}
            </span>
            click on the
            <span onClick={onClickAddAvatar} className=" inline-block mx-1 border-black border p-[2px] rounded-[10px]">
              <div className="flex justify-center items-center w-6 h-6 bg-black rounded-[8px] text-white">+</div>
            </span>
            button.
          </div> */}
          <ButtonAdd onClick={onClickAddAvatar} />
        </div>
      ) : (
        <div className="pt-6 px-3 md:px-7 h-full w-full flex flex-col justify-between">
          <div>
            <div className="text-xl font-bold mb-2">Select Avatars</div>
            <div className="flex flex-row pb-2 overflow-x-auto">
              {avatars.map((avatar) => (
                <AvatarMin
                  className="mr-2"
                  avatar={avatar}
                  key={avatar.id}
                  isSelected={selectedAvatarId === avatar.id}
                  onClick={() => navigate(`/dashboard?avatarId=${avatar.id}`)}
                  onDeleteClick={() => onClickDeleteAvatar(avatar.id)}
                  ref={selectedAvatarId === avatar.id ? selectedAvatarRef : null}
                />
              ))}
              <ButtonAdd onClick={onClickAddAvatar} />
            </div>
            {selectedAvatarId && jobs.length > 0 && <div className="text-xl font-bold mb-2">Your Creations</div>}
          </div>
          <div className="flex-1 overflow-y-auto">
            <div className="grid grid-cols-2 md:grid-cols-3 gap-[12px] lg:flex lg:flex-row lg:flex-wrap">
              {selectedAvatarId
                ? jobs.map((job) => (
                    <JobMin
                      job={job}
                      key={job.id}
                      onClick={() => {
                        if (job.status === "completed") navigate("/view-video/" + job.id);
                      }}
                    />
                  ))
                : undefined}
            </div>
          </div>
          <div className="w-full flex justify-center">
            {selectedAvatarId && <MainButton onClick={onClickCreateVideo} text="Create New Video" className="w-full lg:w-[300px]  my-4" />}
          </div>
        </div>
      )}
    </div>
  );
}

export default Dashboard;
