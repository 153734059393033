import { getStripePayments, getProducts, createCheckoutSession as createCheckoutSessionInternal } from "@stripe/firestore-stripe-payments";
import { app, getHttpsCallableFunction } from "./firebase.service";
import { Product } from "@stripe/firestore-stripe-payments/lib/product";

export const payments = getStripePayments(app, {
    productsCollection: "products",
    customersCollection: "customers",
});

export let products: Product[] = []
getProducts(payments, {
    includePrices: true,
    activeOnly: true,
}).then((results) => {
    products = results;
});

export async function createCheckoutSession(priceId: string) {
    const session = await createCheckoutSessionInternal(payments, {
        price: priceId,
        success_url: window.location.origin + "/dashboard",
        cancel_url: window.location.origin + "/subscribe"
    });
    window.location.assign(session.url);
}

export async function gotoCustomerPortal() {
    const createPortalLink = getHttpsCallableFunction("ext-firestore-stripe-payments-createPortalLink")

    const { data } = await createPortalLink({ returnUrl: window.location.origin + "/settings" });
    window.location.assign((data as { url: string }).url);
}