import React, { useEffect, useState } from "react";

export type GenderFilter = "all" | "male" | "female";
export type AgeFilter = "all" | "youth" | "adult" | "senior";
export type SpecialFilter = "all" | "celebrity" | "cartoon";

interface AvatarCreationFaceFilterProps {
  onFilterChange: (gender: GenderFilter, age: AgeFilter, special: SpecialFilter) => void;
}

function AvatarCreationFaceFilter({ onFilterChange }: AvatarCreationFaceFilterProps) {
  const [gender, setGender] = useState<GenderFilter>("all");
  const [age, setAge] = useState<AgeFilter>("all");
  const [special, setSpecial] = useState<SpecialFilter>("all");

  useEffect(() => {
    onFilterChange(gender, age, special);
  }, [gender, age, special]); // eslint-disable-line react-hooks/exhaustive-deps

  const onGenderChange = (event: any) => {
    const value: GenderFilter = event.target.value;
    setGender(value);
  };
  const onAgeChange = (event: any) => {
    const value: AgeFilter = event.target.value;
    setAge(value);
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onSpecialChange = (event: any) => {
    const value: SpecialFilter = event.target.value;
    setSpecial(value);
  };

  return (
    <div>
      <div className="flex items-start mt-2">
        <div className="font-bold w-[150px]">Age</div>
        <div className="flex flex-col items-center w-[100px]">
          <input className="mr-2 accent-black" type="radio" name="age" id="age_all" value="all" checked={age === "all"} onChange={onAgeChange} />
          <label htmlFor="age_all" className={age !== "all" ? "text-gray-400" : ""}>
            all
          </label>
        </div>
        <div className="flex flex-col items-center  w-[100px]">
          <input className="mr-2 accent-black" type="radio" name="age" id="age_youth" value="youth" checked={age === "youth"} onChange={onAgeChange} />
          <label htmlFor="age_youth" className={age !== "youth" ? "text-gray-400" : ""}>
            youth
          </label>
        </div>
        <div className="flex flex-col items-center  w-[100px]">
          <input className="mr-2 accent-black" type="radio" name="age" id="age_adult" value="adult" checked={age === "adult"} onChange={onAgeChange} />
          <label htmlFor="age_adult" className={age !== "adult" ? "text-gray-400" : ""}>
            adult
          </label>
        </div>
        <div className="flex flex-col items-center  w-[100px]">
          <input className="mr-2 accent-black" type="radio" name="age" id="age_senior" value="senior" checked={age === "senior"} onChange={onAgeChange} />
          <label htmlFor="age_senior" className={age !== "senior" ? "text-gray-400" : ""}>
            senior
          </label>
        </div>
      </div>
      <div className="flex items-start mt-2   max-w-[512px]">
        <div className="font-bold w-[150px]">Gender</div>
        <div className="flex flex-col items-center  w-[100px]">
          <input className="mr-2 accent-black" type="radio" name="gender" id="gender_all" value="all" checked={gender === "all"} onChange={onGenderChange} />
          <label htmlFor="gender_all" className={gender !== "all" ? "text-gray-400" : ""}>
            all
          </label>
        </div>
        <div className="flex flex-col items-center  w-[100px]">
          <input className="mr-2 accent-black" type="radio" name="gender" id="gender_male" value="male" checked={gender === "male"} onChange={onGenderChange} />
          <label htmlFor="gender_male" className={gender !== "male" ? "text-gray-400" : ""}>
            man
          </label>
        </div>
        <div className="flex flex-col items-center  w-[100px]">
          <input className="mr-2 accent-black" type="radio" name="gender" id="gender_female" value="female" checked={gender === "female"} onChange={onGenderChange} />
          <label htmlFor="gender_female" className={gender !== "female" ? "text-gray-400" : ""}>
            woman
          </label>
        </div>
      </div>
      {/* <div className="flex items-start mt-2  max-w-[512px]">
        <div className="font-bold w-[150px]">Special</div>
        <div className="flex flex-col items-center w-[100px]">
          <input
            className="mr-2 accent-black"
            type="radio"
            name="special"
            id="special_all"
            value="all"
            checked={special === "all"}
            onChange={onSpecialChange}
          />
          <label htmlFor="special_all" className={special !== "all" ? "text-gray-400" : ""}>
            all
          </label>
        </div>
        <div className="flex flex-col items-center w-[100px]">
          <input className="mr-2 accent-black" type="radio" name="special" id="special_celebrity" value="celebrity" checked={special === "celebrity"} onChange={onSpecialChange} />
          <label htmlFor="special_celebrity" className={special !== "celebrity" ? "text-gray-400" : ""}>
            celebrity
          </label>
        </div>
        <div className="flex flex-col items-center w-[100px] ">
          <input
            className="mr-2 accent-black"
            type="radio"
            name="special"
            id="special_cartoon"
            value="cartoon"
            checked={special === "cartoon"}
            onChange={onSpecialChange}
          />
          <label htmlFor="special_cartoon" className={special !== "cartoon" ? "text-gray-400" : ""}>
            cartoon
          </label>
        </div>
      </div> */}
    </div>
  );
}

export default AvatarCreationFaceFilter;
