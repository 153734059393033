import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AudioModel from "../components/AudioModel";
import MainButton from "../components/MainButton";
import { useAudioModelStore } from "../domains/audio-model/audio-model.store";
import { createAvatar } from "../domains/avatar/avatar.service";
import { useCustomerStore } from "../domains/customer/customer.store";
import { useFaceModelStore } from "../domains/face-model/face-model.store";
import useQuery from "../hooks/useQuery";
import { trackEvent } from "../services/amplitude.service";
import FaceModel from "../domains/face-model/face-model.model";
import { getCustomFaceModel } from "../domains/custom-face-model/custom-face-model.service";

function AvatarCreationAudio() {
  const { faceId, customFaceId } = useQuery();
  const { audioModels } = useAudioModelStore();
  const { getFaceModel } = useFaceModelStore();
  const { customer } = useCustomerStore();
  const navigate = useNavigate();
  const [selectedAudioModelId, setSelectedAudioModelId] = useState<string | null>(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [selectedFaceModel, setSelectedFaceModel] = useState<FaceModel | null>(null);

  const onClickCreateAvatar = async () => {
    setIsProcessing(true);
    if (!selectedAudioModelId) return console.log("Unable to create avatar: no selectedAudioModelId");
    const selectedAudioModel = audioModels.find((audioModel) => audioModel.id === selectedAudioModelId);
    if (!selectedAudioModel) return console.log("Unable to create avatar: no selectedAudioModel");
    if (!selectedFaceModel) return console.log(`Unable to create avatar: no selectedFaceModel (faceId ${faceId})`);
    if (!customer) return console.log("Unable to create avatar: no customer");
    trackEvent("AvatarCreationAudio_Click_CreateAvatar", { selectedFaceModel, selectedAudioModelId });
    const avatar = await createAvatar(customer?.uid, selectedFaceModel, selectedAudioModel);
    navigate("/dashboard?avatarId=" + avatar.id);
  };

  const onClickAudioModel = (audioModelId: string) => {
    setSelectedAudioModelId(audioModelId);
    trackEvent("AvatarCreationAudio_Click_AudioModel", { audioModelId });
  };

  useEffect(() => {
    (async () => {
      if (!faceId && !customFaceId) navigate("/avatar-creation-face");
      if (faceId) setSelectedFaceModel(getFaceModel(faceId));
      if (customFaceId && customer) {
        const customFaceModel = await getCustomFaceModel(customer.uid, customFaceId);
        if (!customFaceModel) return;
        setSelectedFaceModel({
          age: 30,
          color: customFaceModel.color,
          faceUrl: customFaceModel.referenceUrl,
          gender: customFaceModel.gender,
          id: customFaceModel.id,
          recommendedAudioModels: [],
          special: "none",
        });
      }
    })();
  }, [faceId, customFaceId, customer, navigate, getFaceModel]);

  const recommendedAudioModels = audioModels.filter((am) => selectedFaceModel?.recommendedAudioModels.includes(am.id));
  const genderAudioModels = audioModels.filter((am) => selectedFaceModel?.gender === am.genderVoice);
  return (
    <div className={`h-inner w-screen`}>
      <div className="px-3 md:px-7 pt-6 w-full h-full flex flex-col">
        <div className="text-xl font-bold mb-4 mr-[50px]">Avatar Creation (2/2) - Voice selection</div>
        <div className="flex-1 overflow-y-auto">
          {recommendedAudioModels.length > 0 && (
            <div>
              <div className="text-base font-bold mb-2">Recommended</div>
              <div className="flex-1 overflow-y-auto">
                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-8 gap-4">
                  {recommendedAudioModels.map((audioModel) => (
                    <AudioModel
                      isSelected={selectedAudioModelId === audioModel.id}
                      className="inline-block mr-4"
                      key={audioModel.id}
                      audioModel={audioModel}
                      onClick={() => onClickAudioModel(audioModel.id)}
                    />
                  ))}
                </div>
              </div>

              <div className="text-base font-bold mt-6 mb-2">Other</div>
            </div>
          )}

          <div className="flex-1 overflow-y-auto">
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-8 gap-4">
              {genderAudioModels.map((audioModel, i) => (
                <AudioModel
                  isSelected={selectedAudioModelId === audioModel.id}
                  className="inline-block mr-4"
                  key={audioModel.id + i}
                  audioModel={audioModel}
                  onClick={() => onClickAudioModel(audioModel.id)}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="w-full flex justify-center">
          <MainButton text="Create" className="w-full lg:w-[300px] my-4" isDisabled={selectedAudioModelId === null} onClick={onClickCreateAvatar} isLoading={isProcessing} />
        </div>
      </div>
    </div>
  );
  // eslint-disable-next-line
  return (
    <div className={`h-inner w-screen`}>
      <div className="px-3 md:px-7 pt-6 w-full h-full flex flex-col">
        <div className="text-xl font-bold mb-4 mr-[50px]">Avatar Creation (2/2) - Voice selection</div>
        <div className="flex-1 overflow-y-auto">
          <div className="text-base font-bold mb-2">Recommended</div>
          <AudioModel audioModel={audioModels[0]} />
          <div className="text-base font-bold mb-2 mt-6">Strong</div>
          <div className="whitespace-nowrap overflow-x-auto h-[200px]">
            {audioModels.map((audioModel) => (
              <AudioModel
                isSelected={selectedAudioModelId === audioModel.id}
                className="inline-block mr-4"
                key={audioModel.id}
                audioModel={audioModel}
                onClick={() => setSelectedAudioModelId(audioModel.id)}
              />
            ))}
          </div>
          <div className="text-base font-bold mb-2">Strong</div>
          <div className="whitespace-nowrap overflow-x-auto h-[200px]">
            {audioModels.map((audioModel) => (
              <AudioModel
                isSelected={selectedAudioModelId === audioModel.id}
                className="inline-block mr-4"
                key={audioModel.id}
                audioModel={audioModel}
                onClick={() => setSelectedAudioModelId(audioModel.id)}
              />
            ))}
          </div>
          <div className="text-base font-bold mb-2">Strong</div>
          <div className="whitespace-nowrap overflow-x-auto h-[200px]">
            {audioModels.map((audioModel) => (
              <AudioModel
                isSelected={selectedAudioModelId === audioModel.id}
                className="inline-block mr-4"
                key={audioModel.id}
                audioModel={audioModel}
                onClick={() => setSelectedAudioModelId(audioModel.id)}
              />
            ))}
          </div>
          <div className="text-base font-bold mb-2">Strong</div>
          <div className="whitespace-nowrap overflow-x-auto h-[200px]">
            {audioModels.map((audioModel) => (
              <AudioModel
                isSelected={selectedAudioModelId === audioModel.id}
                className="inline-block mr-4"
                key={audioModel.id}
                audioModel={audioModel}
                onClick={() => setSelectedAudioModelId(audioModel.id)}
              />
            ))}
          </div>
        </div>
        <div className="w-full flex justify-center">
          <MainButton text="Create" className="w-full lg:w-[300px] my-4" isDisabled={selectedAudioModelId === null} onClick={onClickCreateAvatar} isLoading={isProcessing} />
        </div>
      </div>
    </div>
  );
}

export default AvatarCreationAudio;
