import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { Customer } from "./customer.model";

interface CustomerState {
  customer: Customer | null;
  setCustomer: (customer: Customer | null) => void;
}

export const useCustomerStore = create<CustomerState>()(
  devtools(
    persist(
      (set) => ({
        customer: null,
        setCustomer: (customer) => set(() => { return { customer } })
      }),
      {
        name: "customer-storage",
      }
    )
  )
);
