import FaceModel from "./face-model.model";
import { useFaceModelStore } from "./face-model.store";

export function setFaceModels(stringFaceModels: string): void {
  const rawFaceModels: any[] = JSON.parse(stringFaceModels) as any[];
  const faceModels: FaceModel[] = rawFaceModels.map((rawFaceModel) => {
    const faceModel: FaceModel = {
      id: rawFaceModel.id,
      faceUrl: rawFaceModel.faceUrl,
      age: rawFaceModel.age,
      gender: rawFaceModel.gender,
      color: rawFaceModel.color,
      special: rawFaceModel.special || "none",
      recommendedAudioModels: rawFaceModel.recommendedAudioModels || [],
    };
    return faceModel;
  });
  useFaceModelStore.getState().setFaceModels(faceModels);
}
